import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'setRowClass',
})
export class SetRowClass implements PipeTransform {
  transform(row: any, tableType?: string): string {
    let className:string = 'hola'; 
    className = `${row.status} ${row.accessType}`;
    return className;
  }
}
