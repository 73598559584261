import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-clean',
  templateUrl: './filter-clean.component.html',
  styleUrls: ['./filter-clean.component.scss']
})
export class FilterCleanComponent implements OnInit {
  @Output() onConfirm = new EventEmitter<Boolean>();

  constructor() { }


  ngOnInit(): void { }

  closeModal(event: boolean) {
    this.onConfirm.emit(event)
  }

  confirmModal(event: boolean) {
    this.onConfirm.emit(event)
  }
}
