<div class="">
  <div class="">
    <button type="button" class="modal-buttons-edit btn font-weight-bold"
      (click)="openModal(addressBuilding, 'address-building-modal')">
      {{'modal.edit' | translate}}</button>
  </div>
</div>
<ng-template #addressBuilding let-d="dismiss">
  <div class="modal-body bg-white border">
    <div class="first-section">
      <div class="d-flex align-items-center">
        <img class="times ml-auto float-right" height="15px" width="15px" src="https://munily-public-cdn.s3.amazonaws.com/administrative/dashboard/icons/times.png"
          (click)="closeModal()">
      </div>
      <div class="row form-group">
        <button class="btn btn-primary col-md-2 offset-md-1"
          (click)="autocompleteAddress(addresSelecter, inputSearch, 'button');">{{'modal.search' | translate}}</button>
        <div class="input-section col-md-6 offset-md-1">
          <input type="text" class="form-control search" #inputSearch
          [(ngModel)]="searchAddress"
          (keyup)="autocompleteAddress(addresSelecter, inputSearch, 'selecter')"
          placeholder="{{'modal.placeholder.address' | translate}}" autocorrect="off" autocapitalize="off"
          spellcheck="off">

          <ng-select #addresSelecter class="adresses form-control" placeholder="{{'modal.placeholder.address' | translate}}"
          [items]="possibleAddresses"
          [closeOnSelect]="true"
          [searchable]="false"
          notFoundText="{{'ngSelect.notFound' | translate}}"
          (change)="selectAdressByGoogle($event)"
          >
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              {{ item.formatted_address }}
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="second-section">
      <div class="modal-map col-12 col-md-12">
        <agm-map style="height: 300px;" [latitude]="latitud" [longitude]="longitud" [zoom]="zoom"
          (mapReady)="mapReadyHandler($event)">
          <agm-marker *ngIf="marker" style="height: 300px" [latitude]="latitud" [longitude]="longitud"
            [markerDraggable]="true" (dragEnd)="mapReadyHandler($event)">
          </agm-marker>
        </agm-map>
      </div>
    </div>
    <label *ngIf="address" class="font-weight-bold">{{ 'modal.address' | translate }}: {{address}}</label>
    <div Class="buttons">
      <button class="btn btn-primary" (click)="saveNewAddress()" [disabled]="statusButtonSave">{{'modal.save' |
        translate}}</button>
    </div>
  </div>
</ng-template>
