<hr *ngIf="module !== 'chat'" />
<div class="row" *ngIf="module === 'buildings'">
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.name' | translate }}</label>
      <input type="text" class="form-control" (keypress)="limitInput($event)" [formControl]="form.controls['name']" />
    </div>
  </div>
  <!-- <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{'modal.admin' | translate}}</label>
      <ng-select
        bindLabel="name"
        bindValue="_id"
        [items]="users"
        [formControl]="form.controls['admin']"
        [closeOnSelect]="true">
        <ng-template ng-label-tmp let-item="item">
          {{item.name}} {{item.lastName}}
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{item.name}} {{item.lastName}}
        </ng-template>
      </ng-select>
    </div>
  </div> -->
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.subscription' | translate }}</label>
      <ng-select
        bindLabel="name"
        bindValue="_id"
        [items]="plans"
        placeholder="{{ 'modal.select' | translate }}"
        [formControl]="form.controls['type']"
        [closeOnSelect]="true"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item | titlecase }}
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.status' | translate }}</label>
      <select class="form-control" placeholder="{{ 'modal.select' | translate }}" [formControl]="form.controls['status']">
        <option *ngFor="let item of status">
          {{ 'status.' + item.name | translate | titlecase }}
        </option>
      </select>
    </div>
  </div>
  <!-- <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <label class="font-weight-bold mb-1">{{ 'Filtrar por fecha' | translate }}</label>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="fill">
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" [placeholder]="startDate" />
            <input matEndDate formControlName="end" [placeholder]="endDate" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div> -->
  <div class="col-md-12 d-flex align-items-center justify-content-center">
    <button class="btn btn-danger text-uppercase clear-button" (click)="clearFilterDate('reservations')">
      {{ 'invitations.placeholder.reset' | translate }}
    </button>
    <button class="btn btn-info text-uppercase" (click)="search()">{{ 'modal.button.search' | translate }}</button>
  </div>
</div>

<div class="row" *ngIf="module === 'reservations'">
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.area' | translate }}</label>
      <ng-select
        bindLabel="name"
        bindValue="_id"
        [items]="areas"
        placeholder="{{ 'modal.area' | translate }}"
        [formControl]="form.controls['area']"
        [closeOnSelect]="true"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item.name }}
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.unity' | translate }}</label>
      <ng-select
        [formControl]="form.controls['unit']"
        typeToSearchText="{{ 'ngSelect.search' | translate }}"
        notFoundText="{{ 'ngSelect.notFound' | translate }}"
        loadingText="{{ 'ngSelect.loading' | translate }}"
        bindLabel="number"
        bindValue="id"
        [items]="unit$ | async"
        placeholder="{{ 'modal.unit' | translate }}"
        [loading]="unitLoading"
        [typeahead]="unitInput$"
        [closeOnSelect]="true"
        (change)="search()"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item.number }}
        </ng-template>
      </ng-select>
    </div>
  </div>

  <div class="col-md-4">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.reservationStatus' | translate }}</label>
      <ng-select
        bindLabel="name"
        bindValue="_id"
        [items]="status"
        placeholder="{{ 'modal.status' | translate }}"
        [formControl]="form.controls['status']"
        [closeOnSelect]="true"
      >
        <ng-template ng-label-tmp let-item="item">
          {{ item.name | translate }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item.name | translate }}
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <label class="font-weight-bold mb-1">{{ 'modal.filterByDate' | translate }}</label>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="fill">
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" [placeholder]="startDate" />
            <input matEndDate formControlName="end" [placeholder]="endDate" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="col-md-12 d-flex align-items-center justify-content-center">
    <div>
      <button class="btn btn-danger text-uppercase clear-button" (click)="clearFilterDate('reservations')">
        {{ 'invitations.placeholder.reset' | translate }}
      </button>
      <button class="btn btn-info text-uppercase" (click)="search()">{{ 'modal.button.search' | translate }}</button>
    </div>
  </div>
</div>

<div class="row" *ngIf="module === 'registration'">
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.name' | translate }}</label>
      <input type="text" class="form-control" [formControl]="form.controls['name']" />
    </div>
  </div>
  <!-- <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.dni' | translate }}</label>
      <input type="text" class="form-control" [formControl]="form.controls['dni']" />
    </div>
  </div> -->
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.unity' | translate }}</label>
      <ng-select
        [(formControl)]="form.controls['unit']"
        typeToSearchText="{{ 'ngSelect.search' | translate }}"
        notFoundText="{{ 'ngSelect.notFound' | translate }}"
        loadingText="{{ 'ngSelect.loading' | translate }}"
        bindLabel="number"
        bindValue="id"
        [items]="unit$ | async"
        placeholder="{{ 'modal.unity' | translate }}"
        [loading]="unitLoading"
        [typeahead]="unitInput$"
        [closeOnSelect]="true"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item.number }}
        </ng-template>
      </ng-select>
    </div>
  </div>
  <!-- <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <label class="font-weight-bold mb-1">{{'Filtrar por fecha' | translate}}</label>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="input-group">
            <input type="text" ngbDatepicker #d="ngbDatepicker"
                   style="padding-right: 25px;"
                   class="form-control "
                   [autoClose]="true"
                   [outsideDays]="'hidden'"
                   [navigation]="'arrows'"
                   (dateSelect)="dateSelected($event)"
                   [formControl]="form.controls['startDate']"
                   (click)="d.toggle();$event.stopPropagation();"
                   (document:click)="closeFix($event, d);" />
            <div class="input-group-append-date">
              <i class="mdi mdi-menu-down" (click)="d.toggle();$event.stopPropagation();"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="input-group">
            <input type="text" ngbDatepicker #g="ngbDatepicker"
                   style="padding-right: 25px;"
                   class="form-control "
                   [minDate]="minDate"
                   [autoClose]="true"
                   [outsideDays]="'hidden'"
                   [navigation]="'arrows'"
                   [formControl]="form.controls['endDate']"
                   (click)="g.toggle();$event.stopPropagation();"
                   (document:click)="closeFix($event, g);" />
            <div class="input-group-append-date">
              <i class="mdi mdi-menu-down" (click)="g.toggle();$event.stopPropagation();"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="col-md-12 d-flex align-items-center justify-content-center">
    <div>
      <button class="btn btn-info text-uppercase" (click)="search()">{{ 'modal.button.search' | translate }}</button>
    </div>
  </div>
</div>

<div class="row" *ngIf="module === 'tenant'">
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.name' | translate }}</label>
      <input type="text" class="form-control" [formControl]="form.controls['name']" />
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.dni' | translate }}</label>
      <input type="text" class="form-control" [formControl]="form.controls['dni']" />
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.email' | translate }}</label>
      <input type="text" class="form-control" [formControl]="form.controls['email']" />
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.unity' | translate }}</label>
      <ng-select
        [formControl]="form.controls['unit']"
        typeToSearchText="{{ 'ngSelect.search' | translate }}"
        notFoundText="{{ 'ngSelect.notFound' | translate }}"
        loadingText="{{ 'ngSelect.loading' | translate }}"
        bindLabel="number"
        bindValue="id"
        [items]="unit$ | async"
        placeholder="{{ 'modal.select' | translate }}"
        [loading]="unitLoading"
        [typeahead]="unitInput$"
        [closeOnSelect]="true"
        (change)="search()"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item.number }}
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group mb-2">
      <label class="font-weight-bold mb-1">{{ 'table.role' | translate }}</label>
      <ng-select
        bindLabel="name"
        bindValue="_id"
        [items]="roles"
        placeholder="{{ 'modal.select' | translate }}"
        [formControl]="form.controls['roles']"
        [closeOnSelect]="true"
      >
        <ng-template ng-label-tmp let-item="item">
          {{ item.name | translate }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item.name | translate }}
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="col-md-12 d-flex align-items-center justify-content-center">
    <div>
      <button class="btn btn-info text-uppercase" (click)="search()">{{ 'modal.button.search' | translate }}</button>
    </div>
  </div>
</div>

<!-- //notShow -->

<div class="row" *ngIf="module === 'visits'">
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.name' | translate }}</label>
      <input type="text" class="form-control" [formControl]="form.controls['name']" />
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.dni' | translate }}</label>
      <input type="text" class="form-control" [formControl]="form.controls['dni']" />
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.status' | translate }}</label>
      <ng-select
        bindLabel="name"
        bindValue="_id"
        [items]="status"
        placeholder="{{ 'modal.select' | translate }}"
        [formControl]="form.controls['status']"
        [closeOnSelect]="true"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item.name }}
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <label class="font-weight-bold mb-1">{{ 'modal.filterByDate' | translate }}</label>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="input-group">
            <input
              type="text"
              ngbDatepicker
              #d="ngbDatepicker"
              style="padding-right: 25px"
              placeholder="{{ 'modal.placeholder.from' | translate }}"
              class="form-control"
              [autoClose]="true"
              [outsideDays]="'hidden'"
              [navigation]="'arrows'"
              (dateSelect)="dateSelected($event)"
              [formControl]="form.controls['startDate']"
              (dateSelect)="dateSelected($event)"
              (click)="d.toggle(); $event.stopPropagation()"
              (document:click)="closeFix($event, d)"
            />
            <div class="input-group-append-date">
              <i class="mdi mdi-menu-down" (click)="d.toggle(); $event.stopPropagation()"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="input-group">
            <input
              type="text"
              ngbDatepicker
              #g="ngbDatepicker"
              style="padding-right: 25px"
              placeholder="{{ 'modal.placeholder.to' | translate }}"
              class="form-control"
              [minDate]="minDate"
              [autoClose]="true"
              [outsideDays]="'hidden'"
              [navigation]="'arrows'"
              [formControl]="form.controls['endDate']"
              (click)="g.toggle(); $event.stopPropagation()"
              (document:click)="closeFix($event, g)"
            />
            <div class="input-group-append-date">
              <i class="mdi mdi-menu-down" (click)="g.toggle(); $event.stopPropagation()"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 d-flex align-items-center justify-content-center">
    <div class="py-2">
      <button class="btn btn-info text-uppercase" (click)="search()">{{ 'modal.button.search' | translate }}</button>
    </div>
  </div>
</div>

<div class="row justify-content-start" *ngIf="module === 'administrative'">
  <div class="col-md-3">
    <div class="form-group">
      <ng-select
        [formControl]="form.controls['unit']"
        typeToSearchText="{{ 'ngSelect.search' | translate }}"
        notFoundText="{{ 'ngSelect.notFound' | translate }}"
        loadingText="{{ 'ngSelect.loading' | translate }}"
        bindLabel="number"
        bindValue="number"
        [items]="unit$ | async"
        placeholder="{{ 'table.unity' | translate }}"
        [loading]="unitLoading"
        [typeahead]="unitInput$"
        [closeOnSelect]="true"
        class="custom"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item.number }}
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="col-md-1 col-lg-2">
    <div class="form-group">
      <ng-select
        bindLabel="name"
        bindValue="value"
        [items]="hasUserActive"
        placeholder="{{ 'building.settings.userActive' | translate }}"
        [formControl]="form.controls['activeUsers']"
        [closeOnSelect]="true"
        class="custom"
      >
        <ng-template ng-label-tmp let-item="item">
          {{ item.name | translate }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item.name | translate }}
        </ng-template>
      </ng-select>
    </div>
  </div>

  <div class="col-md-2">
    <div class="form-group field__input">
      <input type="text" class="form-control" [formControl]="form.controls['cellars']" placeholder="{{ 'modal.store' | translate }}" />
    </div>
  </div>

  <div class="col-md-3 col-lg-2">
    <div class="form-group field__input">
      <input type="text" class="form-control" [formControl]="form.controls['parkings']" placeholder="{{ 'modal.parking' | translate }}" />
    </div>
  </div>

  <!-- <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.owner' | translate }}</label>
      <input type="text" class="form-control" [formControl]="form.controls['proprietor']" />
    </div>
  </div> -->

  <!-- <div class="col-md-3"> filtro para buscar si pertenece al comite
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.committee' | translate }}</label>
      <ng-select bindLabel="name" bindValue="value" [items]="yesNot" placeholder="{{ 'modal.select' | translate }}"
        [formControl]="form.controls['inCommittee']" [closeOnSelect]="true">
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item.name }}
        </ng-template>
      </ng-select> -->

  <div class="col-md-2 btn__actions">
    <div>
      <button class="btn clickeables__filters--clean" (click)="clearFieldBtn()">{{ 'modal.button.clean' | translate }}</button>
    </div>
    <div>
      <button class="btn clickeables__filters--filter clickeables-general" (click)="search()">{{ 'modal.button.filter' | translate }}</button>
    </div>
  </div>
</div>

<!-- //notShow -->

<div class="row" *ngIf="module === 'debts'">
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.unity' | translate }}</label>
      <input type="text" class="form-control" [formControl]="form.controls['unit']" />
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'table.monthDebts' | translate }}</label>
      <input type="text" class="form-control" [formControl]="form.controls['months']" />
    </div>
  </div>
  <!--<div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold">{{'modal.typeDebts' | translate}}</label>
      <ng-select
        bindLabel="name"
        bindValue="_id"
        [items]="typeDebts"
        placeholder="{{'modal.select' | translate}}"
        [formControl]="form.controls['typeDebt']"
        [closeOnSelect]="true">
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{item.name}}
        </ng-template>
      </ng-select>
    </div>
  </div>-->
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <label class="font-weight-bold mb-1">{{ 'Filtrar por fecha' | translate }}</label>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="input-group">
            <input
              type="text"
              ngbDatepicker
              #d="ngbDatepicker"
              style="padding-right: 25px"
              class="form-control"
              [autoClose]="true"
              [outsideDays]="'hidden'"
              [navigation]="'arrows'"
              (dateSelect)="dateSelected($event)"
              [formControl]="form.controls['startDate']"
              (click)="d.toggle(); $event.stopPropagation()"
              (document:click)="closeFix($event, d)"
            />
            <div class="input-group-append-date">
              <i class="mdi mdi-menu-down" (click)="d.toggle(); $event.stopPropagation()"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="input-group">
            <input
              type="text"
              ngbDatepicker
              #g="ngbDatepicker"
              style="padding-right: 25px"
              class="form-control"
              [minDate]="minDate"
              [autoClose]="true"
              [outsideDays]="'hidden'"
              [navigation]="'arrows'"
              [formControl]="form.controls['endDate']"
              (click)="g.toggle(); $event.stopPropagation()"
              (document:click)="closeFix($event, g)"
            />
            <div class="input-group-append-date">
              <i class="mdi mdi-menu-down" (click)="g.toggle(); $event.stopPropagation()"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3 d-flex align-items-center justify-content-center">
    <div>
      <button class="btn btn-info text-uppercase" (click)="search()">{{ 'modal.button.search' | translate }}</button>
    </div>
  </div>
</div>

<div class="row px-1" *ngIf="module === 'chat'">
  <div class="col-md-6">
    <div class="form-group mb-2">
      <label class="font-weight-bold mb-1">{{ 'modal.name' | translate }}</label>
      <input type="text" class="form-control" [formControl]="form.controls['name']" />
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group mb-2">
      <label class="font-weight-bold mb-1">{{ 'table.role' | translate }}</label>
      <ng-select
        bindLabel="name"
        bindValue="_id"
        [items]="roles"
        placeholder="{{ 'modal.select' | translate }}"
        [formControl]="form.controls['roles']"
        [closeOnSelect]="true"
      >
        <ng-template ng-label-tmp let-item="item">
          {{ item.name | translate }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item.name | translate }}
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="col-md-12 mb-2">
    <button class="btn btn-info btn-sm btn-block text-uppercase" (click)="search()">{{ 'modal.button.search' | translate }}</button>
    <hr />
  </div>
</div>

<!-- //notShow -->

<div class="row px-1" *ngIf="module === 'invitations'">
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.fullName' | translate }}</label>
      <input type="text" class="form-control" (keypress)="limitInput($event)" [formControl]="form.controls['name']" />
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.unity' | translate }}</label>
      <ng-select
        [formControl]="form.controls['unit']"
        typeToSearchText="{{ 'ngSelect.search' | translate }}"
        notFoundText="{{ 'ngSelect.notFound' | translate }}"
        loadingText="{{ 'ngSelect.loading' | translate }}"
        bindLabel="number"
        bindValue="_id"
        [items]="unit$ | async"
        placeholder="{{ 'modal.select' | translate }}"
        [loading]="unitLoading"
        [typeahead]="unitInput$"
        [closeOnSelect]="true"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item.number }}
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <label class="font-weight-bold mb-1">{{ 'modal.filterByDate' | translate }}</label>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="input-group">
            <input
              type="text"
              ngbDatepicker
              #d="ngbDatepicker"
              style="padding-right: 25px"
              placeholder="{{ 'modal.placeholder.from' | translate }}"
              class="form-control"
              [autoClose]="true"
              [outsideDays]="'hidden'"
              [navigation]="'arrows'"
              (dateSelect)="dateSelected($event)"
              [formControl]="form.controls['startDate']"
              (dateSelect)="dateSelected($event)"
              (click)="d.toggle(); $event.stopPropagation()"
              (document:click)="closeFix($event, d)"
            />
            <div class="input-group-append-date">
              <i class="mdi mdi-menu-down" (click)="d.toggle(); $event.stopPropagation()"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="input-group">
            <input
              type="text"
              ngbDatepicker
              #g="ngbDatepicker"
              style="padding-right: 25px"
              placeholder="{{ 'modal.placeholder.to' | translate }}"
              class="form-control"
              [minDate]="minDate"
              [autoClose]="true"
              [outsideDays]="'hidden'"
              [navigation]="'arrows'"
              [formControl]="form.controls['endDate']"
              (click)="g.toggle(); $event.stopPropagation()"
              (document:click)="closeFix($event, g)"
            />
            <div class="input-group-append-date">
              <i class="mdi mdi-menu-down" (click)="g.toggle(); $event.stopPropagation()"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <label class="font-weight-bold mb-1">{{'modal.filterByTime' | translate}}</label>
      </div>
      <div class="col-md-6">
        <ngb-timepicker [formControl]="form.controls['startTime']" [meridian]="true" [spinners]="false"></ngb-timepicker>
      </div>
      <div class="col-md-6">
        <ngb-timepicker [formControl]="form.controls['endTime']" [meridian]="true" [spinners]="false"></ngb-timepicker>
      </div>
    </div>
  </div> -->
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.status' | translate }}</label>
      <ng-select
        bindLabel="name"
        bindValue="_id"
        [items]="status"
        placeholder="{{ 'modal.select' | translate }}"
        [formControl]="form.controls['status']"
        [closeOnSelect]="true"
      >
        <ng-template ng-label-tmp let-item="item">
          {{ item.name | translate }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item.name | translate }}
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.dni' | translate }}</label>
      <input type="text" class="form-control" (keypress)="limitInput($event)" [formControl]="form.controls['dni']" />
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.company' | translate }}</label>
      <input type="text" class="form-control" (keypress)="limitInput($event)" [formControl]="form.controls['company']" />
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.license' | translate }}</label>
      <input type="text" class="form-control" (keypress)="limitInput($event)" [formControl]="form.controls['licensePlate']" />
    </div>
  </div>
  <div class="col-md-12 d-flex align-items-center justify-content-center">
    <div class="py-2">
      <button class="btn btn-info text-uppercase" (click)="search()">{{ 'modal.button.search' | translate }}</button>
    </div>
  </div>
</div>

<div class="row px-1" *ngIf="module === 'staff'">
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.name' | translate }}</label>
      <input type="text" class="form-control" [formControl]="form.controls['name']" />
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group mb-2">
      <label class="font-weight-bold mb-1">{{ 'table.role' | translate }}</label>
      <ng-select
        bindLabel="name"
        bindValue="_id"
        [items]="roles"
        placeholder="{{ 'modal.select' | translate }}"
        [formControl]="form.controls['roles']"
        [closeOnSelect]="true"
      >
        <ng-template ng-label-tmp let-item="item">
          {{ item.name | translate }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item.name | translate }}
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label class="font-weight-bold mb-1">{{ 'modal.email' | translate }}</label>
      <input type="text" class="form-control" [formControl]="form.controls['email']" />
    </div>
  </div>
  <div class="col-md-12 d-flex align-items-center justify-content-center">
    <div class="py-2">
      <button class="btn btn-info text-uppercase" (click)="search()">{{ 'modal.button.search' | translate }}</button>
    </div>
  </div>
</div>
