import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.css'],
})
export class ModalConfirmationComponent implements OnInit {
  @Output() onConfirm = new EventEmitter();
  @Input() isLoading: boolean;
  @ViewChild('content', { static: true }) bodyContent: ElementRef;

  modalRef: any;
  title: string = '';
  confirmText: string = '';
  rejectText: string = '';
  bodyText: string = '';
  subtitle: string = '';
  buttonsInverse: boolean = false

  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  openModal(params: any) {
    this.title = params.title;
    this.subtitle = params.subtitle;
    this.bodyText = params.bodyText;
    this.confirmText = params.confirmText;
    this.rejectText = params.rejectText;
    this.buttonsInverse = params.buttonsInverse ? params.buttonsInverse : false;
    this.modalRef = this.modalService.open(this.bodyContent, {
      container: '.content--modal-form-confirmation',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  closeModal() {
    this.modalRef.close();
  }

  doConfirm() {
    this.onConfirm.emit(true);
  }
}
