import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { infoServices } from 'src/app/interface/buildingServices';

@Component({
  selector: 'app-superadmins-modal',
  templateUrl: './superadmins-modal.component.html',
  styleUrls: ['./superadmins-modal.component.scss'],
})
export class SuperadminsModalComponent implements OnInit {
  @Input() index: number;
  @Output() onConfirm = new EventEmitter<Boolean>();

  infoToShowHtml: infoServices;

  informationToShow: infoServices[] = [
    {
      title: 'servicessuper.services.titlesave',
      succes: 'servicessuper.services.save',
      cancel: 'servicessuper.services.cancel',
    },
    {
      title: 'servicessuper.services.titledelete',
      succes: 'servicessuper.services.toaccept',
      cancel: 'servicessuper.services.cancel',
    },
    {
      title: '¿Deseas guardar los cambios realizados?',
      description: 'Recuerda revisar los cambios a guardar para que no afectes el proceso',
      image: 'https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/alertdanger.png',
      succes: 'servicessuper.services.toaccept',
      cancel: 'servicessuper.services.cancel',
    }
  ];

  constructor() { }

  ngOnInit(): void {
    this.infoToShowHtml = this.informationToShow[this.index];
  }

  confirmModal(event: boolean) {
    this.onConfirm.emit(event);
  }

  closeModal(event: boolean) {
    this.onConfirm.emit(event);
  }
}
