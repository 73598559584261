export enum CallProviders{
  'agora'='agora',
  'twillio' = 'twillio' 
};

export enum CallType{
  selfRegister = 'self-register',
  vigilantCallInvitation = 'vigilant-call-invitation',
  vigilantCallDirectory = 'vigilant-call-directory',
  unknown = 'unknown'
};

export enum ColumsForCallsTable{
  user = 'user',
  unit = 'unit',
  type = 'type',
  status = 'status',
  date = 'date',
  record = 'record'
}

export enum CallNotificationStatus {
  queued = 'queued',
  ringing = 'ringing',
  inProgress = 'in-progress',
  completed = 'completed',
  busy = 'busy',
  failed = 'failed',
  noAnswer = 'no-answer',
  notCall = 'not-call',
  unknown = 'unknown',
}

export interface RecordingMunilyCall {
  urlRecord: string;
  duration: string;
}

export interface UserTo {
  id: string;
  photoUrl: string;
  name: string;
}

export interface CallNotificationAdminResponseDTO {
  user: Partial<UserTo>;
  unit:string;
  type: string;
  status: string;
  date: string;
  id: string;
  recording?: RecordingMunilyCall;
}
