import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Actions, StatusV2 } from 'src/app/common-components/dynamic-table/dynamic-table.component';
import { ResponsePaginatedUsers } from 'src/app/interface/users.interface';
import { UnityModalUsersTabComponent } from "../../administrative/unity-modal-users-tab/unity-modal-users-tab.component";
import { SlideInOutAnimation } from '../../../animations/animations';
import { UserService } from 'src/app/services/user.service';
import { ACTION_LOADING } from '../../../component/store/actions/actions';
import { SuccessProcessModalComponent } from "../../administrative/success-process-modal/success-process-modal.component";
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { CommonService } from 'src/app/services/common.service';
import { AmplitudeEvent } from 'src/app/interface/amplitude.interface';

export enum IconsGlobal {
  infoBlue = 'https://munily-public-cdn.s3.amazonaws.com/general/infoBlue.png',
  checkgray = 'https://munily-public-cdn.s3.amazonaws.com/general/checkGray.png',
  checkOrange = 'https://munily-public-cdn.s3.amazonaws.com/general/checkOrange.png',
  editGray = 'https://munily-public-cdn.s3.amazonaws.com/general/editGray.png',
  cancelGray = 'https://munily-public-cdn.s3.amazonaws.com/general/cancelGray.png',
  editDark = 'https://munily-public-cdn.s3.amazonaws.com/general/editDark.png',
  closeGray = 'https://munily-public-cdn.s3.amazonaws.com/general/closeGray.png',
  editV2Gray ='https://munily-public-cdn.s3.amazonaws.com/general/respond.svg',
  download = 'https://munily-public-cdn.s3.amazonaws.com/onboarding/dashboard/icons/Icons_download.png',
  downloadBlue = 'https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/Icon_file_download.svg'
}

@Component({
  selector: 'app-users-tab',
  templateUrl: './users-tab.component.html',
  styleUrls: ['./users-tab.component.scss'],
  animations: [SlideInOutAnimation],
})
export class UsersTabComponent implements OnInit {
  @ViewChild('modalUnitUsers', { static: false }) modalUnitUsers: UnityModalUsersTabComponent;
  @ViewChild('modalSuccessProcess', { static:false }) modalSuccessProcess: SuccessProcessModalComponent;
  @Input() buildingId: string;
  @Output() openModalEditUser = new EventEmitter<string>();
  icons = IconsGlobal;
  query: string = '';
  page: number = 1;
  rowsPerPage: number = 10;
  data: any = [];
  paginated: any;
  user;

  title: string;
  subtitle: string;
  note: string;
  displayModal = false;

  haveActions = [Actions.edit, Actions.disable, Actions.remove]
  showFilters = ['name', 'role', 'unit', 'email']
  select = ['name', 'unit unitNumber', 'roles .rols', 'email'];
  headers = ['Nombre', 'Unidad', 'Rol', 'Correo'];

  activationUser: StatusV2;

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private amplitudeService: AmplitudeService,
    private commonService: CommonService) { }

  ngOnInit(): void {
    this.getUsers()
  }

  getUsers() {
    this.userService.isLoading({ action: ACTION_LOADING, payload: true });
    let newQuery:any = {};
    this.query.split('&').map((q:string)=>{
      if(q){
        let keys:string[] = q.split('=');
        newQuery[keys[0]] = keys[1];
      }
    });
    this.userService.getUsers(this.page, this.rowsPerPage, newQuery).subscribe(
      ({data}: ResponsePaginatedUsers) => {
        this.data = data.docs;
        this.paginated = data;
         this.userService.isLoading({ action: ACTION_LOADING, payload: false });
      },
      err =>{
        console.error(err)
        this.userService.isLoading({ action: ACTION_LOADING, payload: false });  
      }
    )
  }

  getUsersByPage(page: number) {
    this.page = Number(page);
    this.getUsers()
  }

  restartPage() {
    this.page = 1;
  }

  getQueryFilter(query: string) {
    this.query = query;
    this.restartPage()
    this.getUsers()
  }

  chooseActions() {
    if (this.activationUser == StatusV2.disable) this.disable();
    if (this.activationUser == StatusV2.enable) this.enable();
    if (this.activationUser == StatusV2.remove) this.remove();
  }

  disable() {
    this.displayModal = false;
    this.userService.deactivateUser(this.user.id).subscribe(
      res => {
        this.getUsers()
        this.toastr.success(this.translate.instant('toastrMessage.success.userDisabled'));
      },
      err => console.error(err)
    )
  }

  remove() {
    this.displayModal = false;
    this.userService.removeUser(this.user.id).subscribe(
      res => {
        this.getUsers()
        this.toastr.success(this.translate.instant('toastrMessage.success.userDeleted'));
      },
      err => console.error(err)
    )
  }

  disableConfirm(data) {
    this.setModalToDisable(data)
    this.activationUser = StatusV2.disable;
    this.user = data;
    this.displayModal = true;
  }

  removeConfirm(data) {
    this.setModalToRemove(data)
    this.activationUser = StatusV2.remove;
    this.user = data;
    this.displayModal = true;
  }

  enable() {
    this.displayModal = false;
    this.userService.activateUser(this.user.id).subscribe(
      res => {
        this.getUsers()
        this.toastr.success(this.translate.instant('toastrMessage.success.userEnabled'));
      },
      err => console.error(err)
    )
  }

  enableConfirm(data) {
    this.setModalToEnable(data)
    this.activationUser = StatusV2.enable;
    this.user = data;
    this.displayModal = true;
  }

  openModalUnitUsers() {
    this.modalUnitUsers.openModal();
  }


  openSuccessProcess(selectModalInfoToShow:number) {
    this.modalSuccessProcess.openModal(selectModalInfoToShow);
  }

  onComplete() {
    this.openSuccessProcess(0);
    this.getUsers()
  }

  sendDbToExcel(){
    this.userService.isLoading({ action: ACTION_LOADING, payload:true });
    this.userService.sendDbUserForAdmin().subscribe((res:any) => {
      if(res.data){
        this.openSuccessProcess(1);
        this.amplitudeService.sendToAmplitude({
          event_type: AmplitudeEvent.usersExcelDownloaded,
          user_id: this.commonService.getUser().id,
        });
        this.userService.isLoading({ action: ACTION_LOADING, payload:false });
      }
      }, err => console.log(err)
    )
  }

  denied() {
    this.displayModal = false;
  }

  edit(data) {
    this.openModalEditUser.emit(data);
  }

  sort(sort) {
    this.restartPage()
    this.query = this.userService.addSortToQuery(this.query, sort)
    this.getUsers()
  }

  openModalUser() {
    this.openModalEditUser.emit();
  }

  refreshData() {
    this.getUsers()
  }

  setModalToDisable(user) {
    const { name } = user;
    this.title = this.translate.instant('modalV2.disableUser.title')
    this.subtitle = this.translate.instant('modalV2.disableUser.subtitle', {name})
    this.note = this.translate.instant('modalV2.disableUser.note')
  }

  setModalToRemove(user) {
    const { name } = user;
    this.title = this.translate.instant('modalV2.removeUser.title')
    this.subtitle = this.translate.instant('modalV2.removeUser.subtitle', {name})
    this.withoutNote()
  }

  setModalToEnable(user) {
    const { name } = user;
    this.title = this.translate.instant('modalV2.enableUser.title')
    this.subtitle = this.translate.instant('modalV2.enableUser.subtitle', {name})
    this.withoutNote()
  }

  withoutNote() {
    this.note = '';
  }

}
