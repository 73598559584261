<div class="custom--modal-form">
  <div class="modal_header d-flex align-items-center pb-2">
    <button type="button" class="modal_header-close px-0" aria-label="Close" (click)="closeModal(false)">
      <span aria-hidden="true" class="modal_header-icon">&times;</span>
    </button>
  </div>

  <div class="row">
    <div class="col-md-12 text-center w-100" *ngIf="infoToShowHtml.image">
      <div class="mt-2 mb-2">
        <img [src]="infoToShowHtml.image" />
      </div>
    </div>
    <div class="col-md-12">
      <div class="title mt-1">
        <p>{{ infoToShowHtml.title | translate }}</p>
      </div>
    </div>
    <div class="col-md-12">
      <div class="description__text mt-1" *ngIf="infoToShowHtml.optionalDescriptionText">
        <p>{{ infoToShowHtml.optionalDescriptionText | translate }}</p>
      </div>
    </div>
    <div class="col-md-12 mb-3" *ngIf="users">
      <ul class="list">
        <li class="list-item" *ngIf="users.emailsUsers.length > 0">
          <p class="list-item--title">{{ 'administrative.residentAssociates' | translate }}</p>
          <div class="list-item-info align-center">
            <p class="list-item-info-view">
              <span class="list-item--info">{{ infoToShowHtml.description | translate }}</span>
              <ng-container *ngFor="let user of viewUsersEmail; let i = index">
                {{ user | filtercategory: i:viewUsersEmail.length }}
              </ng-container>
              <span *ngIf="users.emailsUsers.length > 3" class="view-all">
                <span (click)="viewEmailStatus(!viewEmail)">{{ (!viewEmail ? 'dashboard.seeAll' : 'dashboard.seeLess') | translate }}</span>
              </span>
            </p>
          </div>
          <div (click)="goToUnitTenants()" class="d-flex align-items-center seeDetails">
            <img src="https://munily-public-cdn.s3.amazonaws.com/general/eye.png" width="18" class="mr-3">
            <p class="detailsText">{{'modal.usersDetails' | translate}}</p>
          </div>
          <hr />
        </li>


        <span>
          <li class="list-item" *ngIf="users.reservation > 0">
            <p class="list-item--title">{{ 'administrative.currentReservations' | translate }}</p>
            <p class="list-item--info">
              {{ 'administrative.unitHas' | translate }}
              <i>
                <b> {{ users.reservation }} {{ 'administrative.currentReservations' | translate }} </b></i
              >
            </p>
            <hr />
          </li>
          <li class="list-item" *ngIf="users.frequentAccess > 0">
            <p class="list-item--title">{{ 'administrative.frequentAccessForce' | translate }}</p>
            <p class="list-item--info">
              {{ 'administrative.unitHas' | translate }}
              <i
                ><b> {{ users.frequentAccess }} {{ 'administrative.frequentAccessForce' | translate }} </b></i
              >
            </p>
            <hr />
          </li>
          <li class="list-item" *ngIf="users.invitation > 0">
            <p class="list-item--title">{{ 'administrative.fastPass' | translate }}</p>
            <p class="list-item--info">
              {{ 'administrative.unitHas' | translate }}
              <i
                ><b> {{ users.invitation }} {{ 'administrative.fastPassActive' | translate }} </b></i
              >
            </p>
            <hr />
          </li>
          <li class="list-item" *ngIf="users.tempUser > 0">
            <p class="list-item--title">{{ 'administrative.PreRegistrations' | translate }}</p>
            <p class="list-item--info">
              {{ 'administrative.unitHas' | translate }}
              <i>
                <b> {{ users.tempUser }} {{ 'administrative.PreRegistrationsPending' | translate }} </b></i
              >
            </p>
            <hr />
          </li>
          <li class="list-item" *ngIf="users.transactions > 0">
            <p class="list-item--title">{{ 'administrative.associatedTransactions' | translate }}</p>
            <p class="list-item--info">
              {{ 'administrative.unitHas' | translate }}
              <i>
                <b> {{ users.transactions }} {{ 'administrative.associatedTransactions' | translate }} </b></i
              >
            </p>
            <hr />
          </li>
        </span>
      </ul>
    </div>

    <div class="col-md-12 d-flex align-items-baseline justify-content-center w-100">
      <div class="row btn-succes clickeables-general" *ngIf="infoToShowHtml.cancel">
        <button class="btn text-black w-100" type="button" (click)="closeModal(false)">
          {{ infoToShowHtml.cancel | translate }}
        </button>
      </div>
      <div class="row btn-rejection clickeables-general" *ngIf="infoToShowHtml.success">
        <button class="btn text-white w-100" type="button" (click)="confirmModal(true)">
          {{ infoToShowHtml.success | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
