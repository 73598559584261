import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable } from 'rxjs';

enum CAMERAS{
  foward = "foward",
  backwards = "backwards",
}

@Component({
  selector: 'app-webcam-modal',
  templateUrl: './webcam-modal.component.html',
  styleUrls: ['./webcam-modal.component.scss']
})
export class WebcamModalComponent implements OnInit {
@ViewChild('WebcamFeature', { static: true }) webcam: ElementRef;
@Output() sendPhoto:EventEmitter<string> = new EventEmitter();

modalRef:any;
previewImage:WebcamImage = null;
takingPhoto:boolean = true;
trigger:Subject<void> = new Subject();
switchCamera:Subject<boolean | string> = new Subject();
actualCamera:string = CAMERAS.foward;
devicesIds:string[] = [];
allowCameraSwitch:boolean = false;

get $trigger():Observable<void>{
  return this.trigger.asObservable();
}

get $switchCameratrigger():Observable<boolean | string>{
  return this.switchCamera.asObservable();
}

  constructor(
    private translateService: TranslateService,
    private modalService: NgbModal, 
    private toastr: ToastrService) 
  { }

  ngOnInit(): void {
    // Se revisa la cantidad de camaras que hay conectadas
    navigator.mediaDevices.enumerateDevices().then((devices)=>{
      for(let d of devices){
        if(d.kind == 'videoinput') this.devicesIds.push(d.deviceId);
      }
      if(this.devicesIds.length > 1) this.allowCameraSwitch = true;
    })
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      console.warn("Camera access was not allowed by user!");
    }
  }

  snapShot(capture:WebcamImage){
    this.takingPhoto = false;
    this.previewImage = capture;
  }

  takePhoto(){
    this.trigger.next();
  }

  switchCam(){
    let cameraNum:number = this.devicesIds.findIndex((d)=> d == this.actualCamera);
    if(cameraNum == this.devicesIds.length - 1){
      this.actualCamera = this.devicesIds[0]; 
    }else{
      this.actualCamera = this.devicesIds[cameraNum + 1]; 
    }
    this.switchCamera.next(this.actualCamera);
  }

  cameraSwitched(camera:string){
    console.log(camera);//Actual Camera ID
  }

  sendImageAsBase64(){
    if(this.previewImage){
      this.sendPhoto.emit(this.previewImage.imageAsBase64);
      this.modalRef.close();
    }else{
      this.toastr.error(this.translateService.instant('toastrMessage.error.hasntTakenAPhoto'));
    }
  }

  openCamera(){
    this.modalRef = this.modalService.open(this.webcam, {
      windowClass: `webcam-modal`,
      backdropClass: 'blur-backdrop webcam',
      backdrop: true,
      keyboard: true,
      centered: true,
      size: 'lg',
    });
  }
}
