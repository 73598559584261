import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
