import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-status-building-admin-modal',
  templateUrl: './status-building-admin-modal.component.html',
  styleUrls: ['./status-building-admin-modal.component.scss']
})
export class StatusBuildingAdminModalComponent implements OnInit {
  
  constructor( private modalService: NgbModal, private router: Router ) { }

  ngOnInit(): void {
  }

  closeModal(){
    this.router.navigate(['./app/buildings-admin']);
    this.modalService.dismissAll();
  }
}
