import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum Icons {
  disable = 'https://munily-public-cdn.s3.amazonaws.com/general/disableCircleRedModal.png',
  enable = 'https://munily-public-cdn.s3.amazonaws.com/general/checkCircleGreen.png',
  update = 'https://munily-public-cdn.s3.amazonaws.com/general/editicon.png',
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit {
  icons = Icons;
  @Input() show = false;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() note: string;
  @Input() optionalText: {
    optionalTitle: string;
    optionalSubtitle: string;
  };
  @Input() type: string;
  @Input() showButtonSuccess: boolean = true;
  @Input() showButtonCancel: boolean = true;

  @Output() success = new EventEmitter();
  @Output() denied = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  successEvent() {
    this.success.emit();
  }

  deniedEvent() {
    this.close();
    this.denied.emit();
  }

  close() {
    this.show = false;
  }
}
