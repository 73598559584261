<div *ngIf="show" class="contenedor">
  <div class="container">
    <div class="card">
      <div class="card-body text-center">
        <div class="text-center mb-3 mt-4">
          <img [src]="icons[type]" alt="" width="70" />
        </div>
        <h1 class="mx-5">{{ title }}</h1>
        <h5 class="mb-1">{{ subtitle }}</h5>
        <span *ngIf="note" class="mt-2">
          <hr />
          <h6>{{ note }}</h6>
        </span>
        <div *ngIf="optionalText && optionalText.optionalTitle && optionalText.optionalSubtitle" class="mt-2 card-body--optionalText">
          <span>{{ optionalText.optionalTitle }}</span>
          <hr />
          <h6>{{ optionalText.optionalSubtitle }}</h6>
        </div>
      </div>
      <div class="mt-2 mb-5 text-center">
        <button class="btn btnSuccess" *ngIf="showButtonSuccess" (click)="successEvent()">{{ 'buttons.accept' | translate }}</button>
        <button class="btn btnDenied" *ngIf="showButtonCancel" (click)="deniedEvent()">{{ 'buttons.cancel' | translate }}</button>
      </div>
    </div>
  </div>
</div>
