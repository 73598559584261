import { MapsAPILoader } from '@agm/core';
import { Component, EventEmitter, OnInit, OnDestroy, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import {} from 'googlemaps';

declare var google: any;

@Component({
  selector: 'app-modal-building',
  templateUrl: './modal-building.component.html',
  styleUrls: ['./modal-building.component.scss'],
})
export class ModalBuildingComponent implements OnInit, OnDestroy {
  @Output()
  sendNewAddress = new EventEmitter<any>();

  isoCode;
  globalLatitud;
  globalLongitud;
  marker: boolean = false;
  buildingId: any;
  latitud;
  longitud;
  zoom: number;
  address: string;
  statusButtonSave: boolean = false;
  mapClickListener;
  possibleAddresses: any[] = [];
  searchAddress:string = null; 
  searching = null;

  //GOOGLE
  map: google.maps.Map;
  markerGoogle: google.maps.Marker;
  geocoder: google.maps.Geocoder;
  private geoCoder;

  constructor(private modalService: NgbModal,
              private mapsAPILoader: MapsAPILoader,
              private toastr: ToastrService) {}

  ngOnInit(): void {
    this.statusButtonSave = true;
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      this.markerGoogle = new google.maps.Marker({});
    });
  }

  ngOnDestroy(): void {
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
  }

  setCountry(latitud, longitud, isoCode, id?) {
    this.statusButtonSave = true;
    this.buildingId = id;
    this.isoCode = isoCode;
    if (this.buildingId === null) {
      this.marker = false;
    }
    if (this.buildingId) {
      this.marker = true;
      this.latitud = parseFloat(latitud);
      this.longitud = parseFloat(longitud);
      this.globalLatitud = this.latitud;
      this.globalLongitud = this.longitud;
      this.zoom = 18;
      this.getAddress(this.latitud, this.longitud);
    } else {
      this.latitud = parseFloat(latitud);
      this.longitud = parseFloat(longitud);
      this.zoom = 8;
      this.globalLatitud = this.latitud;
      this.globalLongitud = this.longitud;
    }
  }

  autocompleteAddress(selecter:NgSelectComponent, input:HTMLElement, from:string) {
    clearInterval(this.searching);
    if(this.searchAddress === null || this.searchAddress === '') return;
    this.searching = setTimeout(async ()=>{
      this.geoCoder.geocode({address: this.searchAddress } as google.maps.GeocoderRequest)
      .then((result) => {
        const { results } = result;
        this.possibleAddresses = [...results];
        this.openDropDown(selecter, input);
        this.markerGoogle.setPosition(results[0].geometry.location);
        if(from == 'button') this.selectAdressByGoogle(this.possibleAddresses[0]);
        return results;
      })
      .catch((e) => {
        if(this.searching) clearInterval(this.searching);
      });

    }, from === 'selecter' ? 1000 : 100);
    
  }

  selectAdressByGoogle(adress){
    this.searchAddress = adress.formatted_address;
    this.address = adress.formatted_address;
    this.possibleAddresses = [...[]];
    this.latitud = adress.geometry.location.lat();
    this.longitud = adress.geometry.location.lng();
    this.zoom = 16;
    this.statusButtonSave = false;
  }
  public mapReadyHandler(map: google.maps.Map): void {
    this.mapClickListener = map.addListener('click', (e: google.maps.MouseEvent) => {
      this.marker = true;
      this.zoom = 18;
      this.latitud = e.latLng.lat();
      this.longitud = e.latLng.lng();
      this.getAddress(this.latitud, this.longitud);
    });
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 18;
          this.address = results[0].formatted_address;
          this.statusButtonSave = false;
        } else {
          this.toastr.error('No results found');
        }
      } else {
        this.toastr.error('Geocoder failed due to:' + status);
      }
    });
  }

  saveNewAddress() {
    this.globalLatitud = this.latitud;
    this.globalLongitud = this.longitud;
    const newAddressBuilding = {
      modalAddress: {
        address: this.address,
        latitude: this.latitud,
        longitude: this.longitud,
      },
    };

    this.sendNewAddress.emit(newAddressBuilding);
    this.closeModal();
  }

  initLatLng() {
    this.marker = true;
    this.statusButtonSave = true;
    this.latitud = parseFloat(this.globalLatitud);
    this.longitud = parseFloat(this.globalLongitud);
    this.getAddress(this.latitud, this.longitud);
    this.zoom = 12;
  }

  openModal(content, windowClass: any) {
    if (this.latitud === '' && this.longitud === '') {
      this.initLatLng();
    }
    this.modalService.open(content, { windowClass: windowClass, backdrop: 'static', keyboard: false, size: 'xl' }).result.then(
      (result) => {
        this.closeModal();
      },
      (reason) => {},
    );
    this.styleModal(`.${windowClass}`);
    return false;
  }

  styleModal(clase: string) {
    setTimeout(() => {
      let modal = <HTMLElement>document.querySelector(`${clase} .modal-dialog`);
      if (modal) {
        modal.style.height = '-webkit-fill-available';
        modal.style.display = 'flex';
        modal.style.alignItems = 'center';
      }
    }, 1);
  }

  closeModal() {
    if (!this.address) {
      this.sendNewAddress.emit(null);
    }
    this.resetValues();
    this.modalService.dismissAll();
  }

  resetValues() {
    this.address = '';
    this.latitud = '';
    this.longitud = '';
  }

  openDropDown(selecter:NgSelectComponent, input:HTMLElement){
    if(this.possibleAddresses.length > 0){
      selecter.open();
      input.focus();
    }
  }

}
