<div class="p-2 mt-4">
  <table class="table">
    <div class="headers--table--ischeck" *ngIf="onTypeEventTable(haveChecks.TypeEventAtTable)">
      <img [src]="isCheckActive ? icons.isCheck : icons.isNotCheck" alt="Check" (click)="selectedAllUsers(isCheckActive)" width="19" height="19" />
      <span>{{ !isCheckActive ? ('macroCommunity.selectAll' | translate) : ('macroCommunity.deselectAll' | translate) }}</span>
    </div>
    <div class="headers--table--isEdit" *ngIf="haveChecks.DataReception.isEdit">
      <span>{{ haveChecks.DataReception.name }}</span>
      <button (click)="enableCheckTable(true)" class="headers--table-button">
        <mat-icon>edit_square</mat-icon> {{ 'macroCommunity.group.editGroup' | translate }}
      </button>
      <button (click)="enableCheckTable(false)" class="headers--table-button danger">
        <mat-icon>delete</mat-icon> {{ 'macroCommunity.group.deleteGroup' | translate }}
      </button>
    </div>
    <tr class="headers--table">
      <th class="text-center" *ngFor="let head of headers; let i = index">
        {{ head | titlecase}}
        <img *ngIf="(head != '#' && head != 'Acciones')" (click)="sortByName(i)" [src]="icons.switchTable" class="ml-3 pointer" alt="" />
      </th>
    </tr>
    <!-- ROW CELLS -->
    <tr class="content--table pointer" [class.disabled]="prop?.status == 'canceled'" *ngFor="let prop of data; let i = index">
      <td *ngFor="let item of select; let subIndex = index" (click)="onEventTable(i, prop)" [class]="'td-'+item">
        <!--Shows the item count according to pagination -->
        <span *ngIf="(item == 'numeration')" class="text-center">
          <div *ngIf="viewComponent != 'pqrs'">{{ ( i + 1 ) + ((paginated.page - 1) * paginated.limit) }}</div>
          <div *ngIf="viewComponent == 'pqrs'">{{ getPlainProperty(prop[item]) }}</div>
        </span>

        <!--Is Shown when the property we want to show is inside another -->
        <span *ngIf="isIterable(item) && item != 'numeration'" class="text-center">
          {{ getPropertyInside(prop, item) }}
        </span>
        <img
          *ngIf="onTypeEventTable(haveChecks.TypeEventAtTable) && !isIterable(item) && item != 'Acciones' && subIndex === 0"
          [src]="prop.iconCheck ? icons.isCheck : icons.isNotCheck"
        />
        <!-- Displayed when the property has a flat value -->
        <span *ngIf="!isIterable(item) && ((item != 'Acciones') && (item != 'recording.urlRecord') && item != 'numeration')" class="text-center" [ngClass]=" item == 'status' ? 'status-'+ prop[item].split(' ').join('-') : ''">
          {{ getPlainProperty(prop[item]) }}
        </span>

        <!-- Displayed an audio player (exclusive of calls)-->
        <div *ngIf="(item == 'recording.urlRecord')">
          <div *ngIf="prop.recording?.urlRecord">
            <div class="reproductor-bar" >
              <button class="btn" >
                <img *ngIf="!isPlaying?.playing ||  (isPlaying?.playing && isPlaying?.playerRow != i)" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/Icon_action_play_circle.svg" alt="play" (click)="playAudio(i)">
                <img *ngIf="isPlaying?.playing && (isPlaying?.playerRow == i)" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/Icon_action_pause_circle.svg" alt="pause" (click)="stopAudio(i)">
              </button>
              <div #waveform id={{i}}></div>
            </div>
          </div>
          <p class="m-0" *ngIf="!prop.recording?.urlRecord">--</p>
        </div>
        
        <!-- POSIBLES ACTIONS -->
        <span *ngIf="item == 'Acciones'">
          <span class="container-img-actions text-center">
            <!-- SEE DETAILS -->
            <span *ngIf="showAction(actions.view)" class="tooltipa">
              <img (click)="viewAction(prop)" class="img-actions pointer ml-1 viewIcon" width="19" />
              <span class="tooltipatext">{{ 'userActions.view' | translate | titlecase }}</span>
            </span>
            <!-- EDIT -->
            <span class="tooltipa" *ngIf="showAction(actions.edit)">
              <img  (click)="editAction(prop)" class="img-actions pointer" [src]="iconsGlobal.editV2Gray" alt="edit" height="18"/>
              <span class="tooltipatext">{{ 'modal.edit' | translate }}</span>
            </span>
            <!-- CHANGE STATUS -->
            <span class="tooltipa" *ngIf="showAction(actions.disable)">
              <img
                (click)="chooseAction(Status[prop.status], prop)"
                class="img-actions pointer ml-1"
                [ngClass]="Status[prop.status] ? 'disableIcon' : 'enableIcon'"
                [src]=""
                width="16"
                alt=""
              />
              <span class="tooltipatext">{{ 'userActions.' + actions[prop.status] | translate | titlecase }}</span>
            </span>

            <!-- DOWNLOAD -->
              <!-- Just button image (When there is more than one action)-->
              <span class="tooltipa" *ngIf="showAction(actions.download)">
                <img (click)="onEventDownload(prop)" [src]="iconsGlobal.download" class="img-actions pointer ml-1" width="24" alt="" />
                <span class="tooltipatext">{{ 'userActions.download' | translate | titlecase }}</span>
              </span>
              <!-- Button image with text (When is the only action)-->
              <button *ngIf="showAction(actions.download) && prop.recording?.urlRecord" class="btn btn-download-secondary" (click)="onEventDownload(prop)">
                <span>{{ 'userActions.download' | translate | titlecase }}</span>
                <img [src]="iconsGlobal.downloadBlue" class="img-actions pointer ml-1" width="24" alt="" />
              </button>
            <!-- /DOWNLOAD -->

            <!-- REMOVE OR DELETE -->
            <span class="tooltipa" *ngIf="showAction(actions.remove) && prop?.status != 'canceled'">
              <img (click)="removeAction(prop)" class="img-actions pointer ml-1 removeIcon" width="18" alt="" />
              <span class="tooltipatext">{{ 'userActions.remove' | translate | titlecase }}</span>
            </span>
          </span>
        </span>
        <!-- /POSIBLES ACTIONS -->

        <span *ngIf="collapse" class="collapse" class="{{ i.toString() }}">
          <div class="mt-3 text-center">
            <p class="collapse-header m-0 text-left">
              {{ collapse.headers[subIndex] }}
            </p>
            <p *ngIf="isIterable(collapse.select[subIndex])" class="collapse-data text-left m-0">
              {{ getPropertyInside(prop, collapse.select[subIndex]) }}
            </p>
            <p *ngIf="!isIterable(collapse.select[subIndex])" class="collapse-data text-left m-0">
              {{ getPlainProperty(prop[collapse.select[subIndex]]) }}
            </p>
          </div>
        </span>
      </td>
    </tr>
  </table>

  <div class="d-flex align-items-start px-5 mb-5" *ngIf="paginated">
    <span (click)="changePage(1)" class="pointer">
      <img [src]="icons.grayLeftArrow" alt="" />
      <img [src]="icons.grayLeftArrow" alt="" />
    </span>
    <span (click)="changePage(paginated.page - 1)" class="ml-2 pointer">
      <img [src]="icons.grayLeftArrow" alt="" />
    </span>

    <div class="numbers">
      <span class="currentPage">
        {{ paginated.page }}
      </span>

      <span class="nextPages">
        <span (click)="changePage(next1)" *ngIf="next[0]" class="nextPagesItem pointer">{{ next1 }}</span>
        <span (click)="changePage(next2)" *ngIf="next[1]" class="nextPagesItem pointer">{{ next2 }}</span>
        <span (click)="changePage(next3)" *ngIf="next[2]" class="nextPagesItem pointer">{{ next3 }}</span>
      </span>

      <span class="hasMorePages"> ... </span>

      <span (click)="changePage(paginated.totalPages)" *ngIf="paginatedpage != paginated.totalPages" class="totalPages">
        {{ paginated.totalPages }}
      </span>
    </div>

    <span *ngIf="next[0]" (click)="changePage(next1)" class="pointer ml-3">
      <img [src]="icons.blueRightArrow" alt="" />
    </span>
    <span (click)="changePage(paginated.totalPages)" class="ml-2 pointer">
      <img [src]="icons.blueRightArrow" alt="" />
      <img [src]="icons.blueRightArrow" alt="" />
    </span>
    <span class="counts">
      {{paginated.totalDocs}} {{'table.totalMessage' | translate}}
    </span>
  </div>
</div>

<section class="footer" *ngIf="onTypeEventTable(haveChecks.TypeEventAtTable)">
  <div class="footer__selected">
    <p>{{ 'macroCommunity.selectUser' | translate }}</p>
  </div>
  <div class="footer__button">
    <button (click)="onEventEmitFooter(false)" class="footer__button-cancel">{{ 'modal.button.cancel' | translate }}</button>
    <button
      *ngIf="onTypeEventTable(haveChecks.TypeEventAtTable) && haveChecks.DataReception.isEdit"
      (click)="onEventEmitFooter(true)"
      [disabled]="selectedRows.length < 2"
      [ngClass]="selectedRows.length < 2 ? 'footer__button-select-inactive' : 'footer__button-select'"
    >
      {{ 'macroCommunity.group.createGroup' | translate }}
    </button>
    <button
      *ngIf="onTypeEventTable(haveChecks.TypeEventAtTable) && !haveChecks.DataReception.isEdit"
      (click)="onEventEmitFooter(true)"
      class="footer__button-select"
    >
      {{ 'macroCommunity.group.saveChange' | translate }}
    </button>
  </div>
</section>
