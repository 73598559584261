import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { concat, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ACTION_LOADING } from 'src/app/component/store/actions/actions';
import { ResponseGetOneServices } from 'src/app/interface/buildingServices';
import { CoverageAreaService } from 'src/app/services/coverage-area.service';
import { ServiceService } from 'src/app/services/service.service';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-update-service',
  templateUrl: './update-service.component.html',
  styleUrls: ['./update-service.component.scss'],
})
export class UpdateServiceComponent implements OnInit {
  form: FormGroup;
  modalRef: any;
  modalRefSuperAdmin: any;
  listCategories: any[];

  serviceLoading = false;
  listCategories$: Observable<any>;
  categoriesInput$ = new Subject<string>();

  listCoverageArea$: Observable<any>;
  coverageAreaInput$ = new Subject<string>();
  listCoverage: any;

  isVisible: boolean = false;
  phName: string;

  indexRefModal: number;
  indexDeleteRow: number;

  serviceStatus: any[] = [
    { enabled: true, name: 'servicessuper.services.serviceactived' },
    { enabled: false, name: 'servicessuper.services.servicedisabled' },
  ];

  @Output() onConfirm = new EventEmitter<Boolean>();

  @ViewChild('content', { static: true }) bodyContent: ElementRef;
  @ViewChild('modalSuperAdmins', { static: true }) modalContent: ElementRef;

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private serviceService: ServiceService,
    private coverageAreaServices: CoverageAreaService,
    private servicesService: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.getListServices();
    //this.getCoverageAreas();
  }
/* 
  toggleProvider(event: MatSlideToggleChange, provider) {
    const { checked } = event;
    const zone = this.zoneSelected ? this.zoneSelected : this.form.value.zone;
    const providerId = provider.service ? provider.service._id  : provider._id 
    const providerZone = zone.services.find(e => e.service == providerId)
    if (!providerZone) return
    providerZone.enabled = checked;
  } */

/*   selectedZone(zone) {
    this.zoneSelected = zone;
    this.providers = zone.services;
  } */
  

/*   autocompleteZones(zoneInput: NgSelectComponent) {
    this.zones = [];
    this.providers = false;
    if (zoneInput && this.once) {
      this.once = false;
      fromEvent(zoneInput.element, 'keyup')
      .pipe(
        map((event: any) => {
          return event.target.value;
        }),
        filter((res) => res.length > 0),
        debounceTime(500),
        distinctUntilChanged(),
        )
        .subscribe((text: string) => {
          this.getRegions(text)
      });     
    }
  } */

/*   getQueryRegions(search: string, status: string) {
    let query: string;
    if (search) query = query + `&search=${search}`
    if (status) query = query + `&status=${status}`
    return query;
  } */
    

/*   getRegions(search?: string) {
    let query = this.getQueryRegions(search, UnitStatus.active);
    
    this.servicesServices.getZones(query).subscribe(
      ({data}: any) => {
        this.zones = data.docs;
      },
      err => console.error(err)
    )
  } */

  getListServices() {
    this.coverageAreaServices.getCoverageAreas(null, 'active', 10000).subscribe((c) => {
      this.listCoverage = c;
    });
  }

  getCoverageAreas() {
    this.listCoverageArea$ = concat(
      of([]),
      this.coverageAreaInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.serviceLoading = true)),
        switchMap((term) => this.coverageAreaServices.getCoverageAreas(term, 'active')),
      ),
    );
  }

  initForm(data?) {
    this.form = this.fb.group({
      id: [data ? data.id : null],
      munilyTrucksRegion: [data.munilyTrucksRegion ? data.munilyTrucksRegion.id : null],
      name: [data.munilyTrucksRegion ? data.munilyTrucksRegion.name : null],
      enable: [data.config.enable],
      services: this.fb.array([]),
    });

    if(data.regionService.length > 0){
      this.loadCoverageArea(data.regionService);
    } else {
      if (data.munilyTrucksRegion && data.munilyTrucksRegion.services) this.loadCoverageArea(data.munilyTrucksRegion.services);
    }
    this.isVisible = true;
  }

  loadCoverageArea(area) {
    const formServices = this.form.get('services') as FormArray;
    area.forEach((item) => {
      formServices.push(this.createFormService(item));
    });
  }

  createFormService(service): FormGroup {
    let formGroup: FormGroup = new FormGroup({
      enabled: new FormControl(service.enabled),
      id: new FormControl(service.id),
      service: new FormControl(service.service ? service.service : service.service.id),
    });

    return formGroup;
  }

  onSubmit() {
    this.indexRefModal = 0;
    this.modalRefSuperAdmin = this.modalService.open(this.modalContent, { backdrop: 'static', keyboard: false, centered: true, size: 'lg' });
  }

  updateBuildingServices(event: boolean) {
    if (!event) return this.modalRefSuperAdmin.close();

    if (this.indexRefModal == 0) {
      if (this.valid()) return this.modalRefSuperAdmin.close();

      const dataForm = this.form.getRawValue();

      this.serviceService.updateBuildingService(dataForm.id, dataForm).subscribe(
        (Response) => {
          this.onConfirm.emit(Response);

          this.toastr.success(this.translate.instant('servicessuper.success.successsave'));
        },
        (error) => {
          this.showMessageError('servicessuper.error.errorsave');
        },
      );

      this.modalRefSuperAdmin.close();
    } else {
      this.toastr.success(this.translate.instant('servicessuper.success.successdeleterow'));
      this.modalRefSuperAdmin.close();
    }
  }

  valid() {
    const data = this.form.getRawValue();
    let isValid: boolean = false;

    if (data.enable && data.services.length == 0) {
      this.showMessageError('servicessuper.error.errorsavecategories');
      isValid = true;
    }


    return isValid;
  }

  changeAssociation() {
    const isEnable = this.form.get('enable').value;
    this.form.get('enable').setValue(isEnable);
  }

  changeModalCoverageArea(id: string) {
    if (!id) return;
    this.setVisibleLoading(true);
    this.coverageAreaServices.getCoverageArea(id).subscribe((Response) => {
      this.selectArrayServices.clear();
      this.form.controls.munilyTrucksRegion.setValue(Response.data.id);
      this.form.controls.name.setValue(Response.data.name);
      this.loadCoverageArea(Response.data.services);
      this.serviceLoading = false;
      this.setVisibleLoading(false);
    });
  }

  openModal(id: string) {
    this.setVisibleLoading(true);
    this.serviceService.getOneBuildingById(id).subscribe(
      (Response: ResponseGetOneServices) => {
        this.phName = Response.data.name;
        this.initForm(Response.data);
        this.modalRef = this.modalService.open(this.bodyContent, { backdrop: 'static', keyboard: false, centered: true, size: 'lg' });
        this.setVisibleLoading(false);
      },
      (error) => {
        this.showMessageError('servicessuper.error.errorphnotexist');
      },
    );
  }
  selectService(service, eventCheck, position) {
    let data = {
      enabled: eventCheck,
      id: service.id,
      service: {
        id: service.service.id,
        name: service.service.name
      }
    }
    const setService: FormGroup = this.createFormService(data);
    this.selectArrayServices.setControl(position, setService);
  }

  get selectArrayServices(): FormArray {
    return this.form.get('services') as FormArray;
  }

  closeModal() {
    this.form.reset();
    this.modalRef.close();
  }

  setVisibleLoading(isvisible: boolean) {
    this.serviceService.isLoading({ action: ACTION_LOADING, payload: isvisible });
  }

  showMessageError(messageOfError: string) {
    this.toastr.error(this.translate.instant(messageOfError));
  }
}
