import { Component, OnInit, ViewChild, Injector, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Constants } from 'src/app/shared/constants/constants';
import { Chart } from 'chart.js';
import { KpiService } from 'src/app/services/kpi.service';
import * as moment from 'moment';

@Component({
  selector: 'app-metric-chart',
  templateUrl: './metric-chart.component.html',
  styleUrls: ['./metric-chart.component.css'],
})
export class MetricChartComponent implements OnInit {
  @ViewChild('registeredUser', {static: true}) private registeredUser;
  @ViewChild('activeUser', {static: true}) private activeUser;
  @ViewChild('waitingTime', {static: true}) private waitingTime;
  @ViewChild('activityLevel', {static: true}) private activityLevel;

  @Input() inDashboard: boolean;
  @Input() idBuilding: Number;

  commonService = this.injector.get(CommonService);
  kpiService = this.injector.get(KpiService);
  kpiActivityLevel = [];
  dateEvent = null;
  kpiType = null;
  user = null;
  groups = [
    { id: 'day', name: 'Diario' },
    { id: 'week', name: 'Semanal' },
    { id: 'month', name: 'Mensual' },
    { id: 'annual', name: 'Anual' },
    { id: 'origin', name: 'Origen' },
  ];

  graphics = {
    registered: {
      labels: this.constants.labelRegistered,
      datasets: [
        {
          data: [168, 182],
          backgroundColor: ['#FFA240', '#FFD7B3'],
        },
      ],
    },
    active: {
      labels: this.constants.labelMonths,
      datasets: [
        {
          label: '% Usuarios activos',
          data: [21, 10, 40, 21, 10, 40, 21, 10, 40, 21, 10, 40],
          backgroundColor: '#FFA240',
          borderColor: '#FFA240',
          fill: false,
        },
        {
          label: '% Retención',
          data: [39, 20, 10, 39, 20, 10, 39, 20, 10, 39, 20, 10],
          backgroundColor: '#388BEC',
          borderColor: '#388BEC',
          fill: false,
        },
      ],
    },
    waiting: {
      labels: this.constants.labelMonths,
      datasets: [
        {
          label: '% Usuarios activos',
          data: [21, 10, 40, 21, 10, 40, 21, 10, 40, 21, 10, 40],
          backgroundColor: '#FFA240',
          borderColor: '#FFA240',
          fill: false,
        },
      ],
    },
    activity: {
      labels: this.constants.labelActivity,
      datasets: [
        {
          data: [168, 182, 2, 12, 85, 100, 75],
          backgroundColor: '#FFA240',
        },
      ],
    },
  };

  constructor(private injector: Injector, private constants: Constants) {}

  ngOnInit() {
    this.user = this.commonService.getUser();
    this.kpiType = 'day';
    this.dateEvent = {
      year: moment().year(),
      month: moment().month() + 1,
      day: moment().date(),
    };

    const param = {
      isMunily: this.inDashboard,
      idBuilding: this.idBuilding,
      date: moment().toISOString(),
      kpiType: this.kpiType,
    };

    this.kpiService
      .getActivityLevelChart(param)
      .then((r: any) => {
        this.kpiActivityLevel = r;
      })
      .catch((err) => {
        console.log(err);
      });

    // setTimeout(() => {
    //   if (this.registeredUser && this.activeUser && this.waitingTime && this.activityLevel) {
    //     this.registeredUser = new Chart(this.registeredUser.nativeElement, {
    //       type: 'doughnut',
    //       data: {
    //         labels: this.graphics.registered.labels, // your labels array
    //         datasets: this.graphics.registered.datasets
    //       },
    //       options: this.constants.donutOption
    //     });

    //     this.activeUser = new Chart(this.activeUser.nativeElement, {
    //       type: 'line',
    //       data: {
    //         labels: this.graphics.active.labels, // your labels array
    //         datasets: this.graphics.active.datasets
    //       },
    //       options: this.constants.lineOption
    //     });

    //     this.waitingTime = new Chart(this.waitingTime.nativeElement, {
    //       type: 'line',
    //       data: {
    //         labels: this.graphics.waiting.labels, // your labels array
    //         datasets: this.graphics.waiting.datasets
    //       },
    //       options: this.constants.lineOptionNoLegend
    //     });

    //     this.activityLevel = new Chart(this.activityLevel.nativeElement, {
    //       type: 'bar',
    //       data: {
    //         labels: this.graphics.activity.labels, // your labels array
    //         datasets: this.graphics.activity.datasets
    //       },
    //       options: this.constants.graphicsOptionNoLegend
    //     });
    //   }
    // }, 1000);
  }

  searchKpi() {
    const param = {
      isMunily: this.inDashboard,
      idBuilding: this.idBuilding,
      date: moment()
        .set({
          year: this.dateEvent.year,
          month: this.dateEvent.month - 1,
          date: this.dateEvent.day,
        })
        .toISOString(),
      kpiType: this.kpiType,
    };

    this.kpiService
      .getActivityLevelChart(param)
      .then((r: any) => {
        this.kpiActivityLevel = r;
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
