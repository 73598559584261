import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-master',
  templateUrl: './table-master.component.html',
  styleUrls: ['./table-master.component.css'],
})
export class TableMasterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
