<div class="justify-content-center" *ngIf="user && user.superUser">
  <div class="row mb-4">
    <!-- <div class="btn-group btn-group-toggle mb-4" data-toggle="buttons">
      <label class="btn btn-info active">
        <input type="radio" name="options" autocomplete="off" [value]="day"  [(ngModel)]="groupKpi"> {{'dashboard.filter.day' | translate}}
      </label>
      <label class="btn btn-info">
        <input type="radio" name="options" autocomplete="off" [value]="week"  [(ngModel)]="groupKpi"> {{'dashboard.filter.week' | translate}}
      </label>
      <label class="btn btn-info">
        <input type="radio" name="options" autocomplete="off" [value]="month"  [(ngModel)]="groupKpi"> {{'dashboard.filter.month' | translate}}
      </label>
      <label class="btn btn-info">
        <input type="radio" name="options" autocomplete="off" [value]="year"  [(ngModel)]="groupKpi" > {{'dashboard.filter.year' | translate}}
      </label>
      <label class="btn btn-info">
        <input type="radio" name="options" autocomplete="off" [value]="origin"  [(ngModel)]="groupKpi"> {{'dashboard.filter.origin' | translate}}
      </label>
    </div> -->
    <div class="col-md-3">
      <div class="form-group">
        <select [(ngModel)]="kpiType" class="form-control">
          <option [value]="group.id" *ngFor="let group of groups">{{ group.name }}</option>
        </select>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <div class="input-group">
          <input
            type="text"
            ngbDatepicker
            #g="ngbDatepicker"
            style="padding-right: 25px;"
            class="form-control"
            [minDate]="minDate"
            [autoClose]="true"
            [outsideDays]="'hidden'"
            [navigation]="'arrows'"
            [(ngModel)]="dateEvent"
            (click)="g.toggle(); $event.stopPropagation()"
          />
          <div class="input-group-append-date">
            <i class="mdi mdi-menu-down" (click)="g.toggle(); $event.stopPropagation()"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <button class="btn btn-primary text-uppercase" (click)="searchKpi()">{{ 'modal.button.search' | translate }}</button>
      </div>
    </div>
  </div>
  <!-- <div class="btn-group btn-group-toggle mb-4" data-toggle="buttons">
    <label class="btn btn-info active">
      <input type="radio" name="options" id="option1" autocomplete="off" checked> {{'dashboard.filter.day' | translate}}
    </label>
    <label class="btn btn-info">
      <input type="radio" name="options" id="option2" autocomplete="off"> {{'dashboard.filter.week' | translate}}
    </label>
    <label class="btn btn-info">
      <input type="radio" name="options" id="option3" autocomplete="off"> {{'dashboard.filter.month' | translate}}
    </label>
    <label class="btn btn-info">
      <input type="radio" name="options" id="option4" autocomplete="off"> {{'dashboard.filter.year' | translate}}
    </label>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="card wrapper--custom-card w-100">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="my-0">{{'dashboard.registeredUser' | translate}} 350</h5>
          </div>
          <div><hr class="mb-4"/></div>
          <canvas #registeredUser height="200">{{ chart }}</canvas>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card wrapper--custom-card w-100">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="my-0">{{'dashboard.activeUser' | translate}}</h5>
          </div>
          <div><hr class="mb-4"/></div>
          <canvas #activeUser height="200">{{ chart }}</canvas>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card wrapper--custom-card w-100">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="my-0">{{'dashboard.waitingTime' | translate}}</h5>
          </div>
          <div><hr class="mb-4"/></div>
          <canvas #waitingTime height="200">{{ chart }}</canvas>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card wrapper--custom-card w-100">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="my-0">{{'dashboard.activityLevel' | translate}}</h5>
          </div>
          <div><hr class="mb-4"/></div>
          <canvas #activityLevel height="200">{{ chart }}</canvas>
        </div>
      </div>
    </div>-->
  <div class="row">
    <div class="col-md-12">
      <div class="card wrapper--custom-card w-100">
        <div class="card-body">
          <ul>
            <li *ngFor="let item of kpiActivityLevel">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
