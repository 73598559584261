import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { administrativeModal, UnitNotEliminate } from 'src/app/interface/administrative';

@Component({
  selector: 'shared-administrative-modal',
  templateUrl: './administrative-modal.component.html',
  styleUrls: ['./administrative-modal.component.scss'],
})
export class AdministrativeModalComponent implements OnInit {
  @Input() index: number;
  @Input() users?: UnitNotEliminate;
  @Input() unitId?: string;
  @Output() onConfirm = new EventEmitter<Boolean>();

  viewEmail: boolean = false;
  infoToShowHtml: administrativeModal;
  viewUsersEmail: string[];
  displayDetails = false;
  informationToShow: administrativeModal[] = [
    {
      image: 'https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/alert9Delete.png',
      title: 'modalConfirmation.updateStatusUnit',
      success: 'modalConfirmation.button.accept',
      cancel: 'modalConfirmation.button.cancel',
    },
    {
      image: 'https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/alert8Ban.png',
      title: 'administrative.titleModal',
      description: 'administrative.descriptionModal',
      optionalText: 'toastrMessage.success.optinalMesagge',
      success: 'modalConfirmation.button.accept',
    },
    {
      image: 'https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/file_empty.png',
      title: 'administrative.titleAlreadyUnit',
      optionalDescriptionText: 'administrative.descriptionAlreadyUnit',
      success: 'modalConfirmation.button.accept',
    },
  ];

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.infoToShowHtml = this.informationToShow[this.index];

    if (this.users) this.viewEmailStatus();
  }

  confirmModal(event: boolean) {
    this.onConfirm.emit(event);
  }

  closeModal(event: boolean) {
    this.onConfirm.emit(event);
  }

  viewEmailStatus(view?: boolean) {
    this.viewEmail = view;
    this.viewUsersEmail = !this.viewEmail ? this.users.emailsUsers.slice(0, 3) : this.users.emailsUsers;
  }

  goToUnitTenants() {
    this.router.navigate([`app/administrative/unit/update/${this.unitId}`], {queryParams: {tab: 'residents'}})
    this.closeModal(null)
  }
}
