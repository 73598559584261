import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Features } from '../interface/features.interface';
import { UserAdministrationService } from '../macro-community/services/administration/user.service';

@Injectable({
    providedIn: 'root'
  })
  export class DynamicTableService {
    actualFeature:Features = Features.default; //use for reusable functions to identify how they should behave according to the feature
    itemsSelected:any[] = [];
    query:any;

    //Users Table variables
    usersIdList:any[] = []; //They are all the ids of the query, ignoring the pagination

  
  constructor(
    private http: HttpClient,
    private userAdministrationServices: UserAdministrationService
  ){ }

  //Users Table Functions >>>>>>>>>>>>>
  getAllUsersIdFromQuery():void{
    this.userAdministrationServices.getAllUsersId(this.query).subscribe((resp:any)=>{
      this.usersIdList = resp.users.map((uid) => uid);
      this.itemsSelected = [...this.usersIdList];
    },error => console.log(error.message));
  }

  removeAllUsers():void{
    this.itemsSelected = [];
  }

  checkIfItIs(item, list): boolean{
    let itIsIn:boolean = list.findIndex((l:any)=> l == item) != -1;
    return itIsIn;
  }

  removeUserFromList(userId:string):void{
    let index = this.itemsSelected.findIndex((uid)=> uid == userId);
    if(index !== -1) this.itemsSelected.splice(index, 1);     
  }

  addUserTotheList(uid:string):void{
    this.itemsSelected.push(uid);     
  }
  //Users Table Functions <<<<<<<<<<<<<
  
    
}
  