import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ResponseBuildings, ResponseBuildingServices, ResponseGetOneServices } from '../interface/buildingServices';
import { DataService } from './data.service';

@Injectable()
export class ServiceService extends DataService {
  headers: HttpHeaders;
  constructor(private http: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);
    this.headers = new HttpHeaders({
      authorization: JSON.parse(localStorage.getItem('currentUser')).token,
      'fb-token': JSON.parse(localStorage.getItem('currentUser')).fbToken,
    });
  }

  getBuildingServices(): Observable<ResponseBuildingServices> {
    return this.http.get<ResponseBuildingServices>(`${environment.apiUrl}/super/v1/buildings/services`, { headers: this.headers });
  }

  getOneBuildingById(id: string): Observable<ResponseGetOneServices> {
    return this.http.get<ResponseGetOneServices>(`${environment.apiUrl}/super/v1/buildings/services/${id}`, { headers: this.headers });
  }

  getStatusServices(status: boolean): Observable<ResponseBuildingServices> {
    return this.http.get<ResponseBuildingServices>(`${environment.apiUrl}/super/v1/buildings/services?servicesEnable=${status ? 'true' : 'false'}`, {
      headers: this.headers,
    });
  }

  getcountriesAndTimezone(search: string, size: string): Observable<any> {
    let params = new HttpParams();

    if (search) params = params.set('name', search);
    if (size) params = params.set('size', size);
    return this.http.get<any>(`${environment.apiUrl}/public/v1/countries/paginate`, { params: params });
  }

  getBuildings(): Observable<ResponseBuildings> {
    return this.http.get<ResponseBuildings>(`${environment.apiUrl}/super/v1/buildings/list`, { headers: this.headers });
  }

  getCategoriesFilter(search: string, status: string, size: string): Observable<any> {
    let params = new HttpParams();

    if (search) params = params.set('search', search);
    if (status) params = params.set('status', status);
    if (size) params = params.set('size', size);
    return this.http
      .get<any>(`${environment.apiUrl}/super/v1/categories`, { headers: this.headers, params: params })
      .pipe(map((Response: any) => Response.data.docs));
  }

  getCategories(search: string, size: string): Observable<any> {
    let params = new HttpParams();

    if (search) params = params.set('search', search);
    if (size) params = params.set('size', size);

    return this.http
      .get<any>(`${environment.apiUrl}/super/v1/categories`, { headers: this.headers, params: params })
      .pipe(map((Response: any) => Response.data.docs));
  }

  getOneCategoryId(id: string): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/super/v1/categories/${id}`, { headers: this.headers })
      .pipe(map((Response: any) => Response.data));
  }

  getServices(search: string, status: string, size: string): Observable<any> {
    let params = new HttpParams();
    if (search) params = params.set('search', search);
    if (status) params = params.set('status', status);
    if (size) params = params.set('size', size);
    return this.http
      .get<any>(`${environment.apiUrl}/super/v1/services`, { headers: this.headers, params: params })
      .pipe(map((Response: any) => Response.data.docs));
  }

  getMunilyTruckRegion(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/super/v1/munily-trucks/region`, { headers: this.headers })
      .pipe(map((Response: any) => Response.data.docs));
  }

  getFilterCoutryId(id: string, status: boolean): Observable<ResponseBuildingServices> {
    return this.http.get<ResponseBuildingServices>(
      `${environment.apiUrl}/super/v1/buildings/services?country=${id}&servicesEnable=${status ? 'TRUE' : 'FALSE'}`,
      { headers: this.headers },
    );
  }

  getMunilyTruckRegionFilter(search: string, status: string, size: string): Observable<any> {
    let params = new HttpParams();

    if (search) params = params.set('search', search);
    if (status) params = params.set('status', status);
    if (size) params = params.set('size', size);
        
    return this.http
      .get<any>(`${environment.apiUrl}/super/v1/munily-trucks/region`, { headers: this.headers, params: params  })
      .pipe(map((Response: any) => Response.data.docs));
  }

  getFilterServiceId(id: string): Observable<ResponseBuildingServices> {
    return this.http.get<ResponseBuildingServices>(`${environment.apiUrl}/super/v1/buildings/services?serviceId=${id}`, { headers: this.headers });
  }

  getFilterCategoryId(id: string): Observable<ResponseBuildingServices> {
    return this.http.get<ResponseBuildingServices>(`${environment.apiUrl}/super/v1/buildings/services?categoryId=${id}`, { headers: this.headers });
  }

  getPaginateServices(query: any, filters: any): Observable<ResponseBuildingServices> {
    let params = new HttpParams().set('page', query.page).set('limit', query.limit);

    if (filters) {
      if (filters.phname) params = params.set('name', filters.phname);
      if (filters.country) params = params.set('country', filters.country);
      if (filters.services) params = params.set('serviceId', filters.services);
      if (filters.category) params = params.set('categoryId', filters.category);
      if (filters.munilytruckregion) params = params.set('munilyTruckRegionId', filters.munilytruckregion);
      if (filters.status) params = params.set('status', filters.status);
    }

    params = params.set('servicesEnable', filters.servicesEnable ? 'true' : 'false');

    return this.http.get<ResponseBuildingServices>(`${environment.apiUrl}/super/v1/buildings/services`, { headers: this.headers, params: params });
  }

  updateBuildingService(id: string, payload): Observable<any> {
    let data = {
      munilyTrucksRegion: payload.munilyTrucksRegion,
      servicesEnable: payload.enable ? 'TRUE' : 'FALSE',
      regionService: payload.services,
    };

    return this.http.put(`${environment.apiUrl}/super/v1/buildings/services/${id}`, data, { headers: this.headers });
  }

  // Region - Zone
  getZones(queryParams?: string) {
    return this.http.get(`${environment.apiUrl}/super/v1/munily-trucks/region?${queryParams}`);
  }
}
