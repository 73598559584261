<div class="custom--modal-form">
  <div class="d-flex align-items-center">
    <img class="times ml-auto float-right" height="15px" width="15px" src="https://munily-public-cdn.s3.amazonaws.com/general/icons/times.png"
      (click)="closeModal()">
  </div>
  <br><br>
  <label class="font-weight-bold">Puede editar la información de su edificio</label>
  <div Class="buttons">
    <button class="btn btn-primary" (click)="closeModal()">{{'buttons.accept' | translate}}</button>
  </div>
</div>
