<ng-template #areaAdviceModal let-c="close" let-d="dismiss" class="area-configuration-modal-confirmation">
  <div class="custom--modal-form">
    <div class="modal-header py-2 px-2 text-center">
      <img src="https://munily-public-cdn.s3.amazonaws.com/general/exclamation.svg" alt="Icon">
      <h4 class="modal-title">{{'attention' | translate}}</h4>
    </div>
    <div class="modal-body">
      <p>{{'areas.modal.configurationAdvice' | translate}}</p>
    </div>
    <div class="modal-footer py-2 px-2">
      <button type="button" class="btn btn-sm w-25 rounded secondary" [disabled]="isLoading" (click)="closeModal()">{{'buttons.cancel' | translate}}</button>
      <button type="button" class="btn btn-sm w-25 rounded primary" [disabled]="isLoading" (click)="doConfirm()">{{'buttons.accept' | translate}}</button>
    </div>
  </div>
</ng-template>
  