<div class="custom--modal-form">
    <div class="modal_header d-flex align-items-center pb-2">
        <button type="button" class="modal_header-close px-0" aria-label="Close" (click)="confirmModal(false)">
            <span aria-hidden="true" class="modal_header-icon">&times;</span>
        </button>
    </div>

    <div class="row">

        <div class="col-md-12">
            <div class="title mt-3">
                <p>{{infoToShowHtml.title | translate}}</p>
            </div>
        </div>
        <div class="col-md-12 d-flex flex-row justify-content-center align-items-baseline w-100">
            <div class="row  btn-rejection clickeables-general">
                <button class="btn text-white w-100" type="button" (click)="confirmModal(true)">
                    {{ infoToShowHtml.success |
                    translate}}
                </button>
            </div>
            <div class="row  btn-succes  clickeables-general">
                <button class="btn text-black w-100" type="button" (click)="confirmModal(false)">
                    {{ infoToShowHtml.cancel |
                    translate}}
                </button>
            </div>
        </div>
        <div class="col-md-12 d-flex flex-row justify-content-center p-2" *ngIf="infoToShowHtml.newCommunicate">
            <button mat-button class="btn-wrapper" (click)="newCommunicate(true)">
                + {{ 'servicessuper.noticemunily.newcommunicated' | translate}}
            </button>
        </div>
    </div>
</div>