<ng-template #content let-c="close" let-d="dismiss">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <section class="custom--modal-form">
      <div class="modal___body--header pb-2">
        <div class="modal___body--header-title">
          <h1>{{ phName }}</h1>
        </div>
        <div class="modal___body--header-buttom">
          <button type="button" class="modal___body--header-close px-0" aria-label="Close" (click)="closeModal(false)">
            <span aria-hidden="true" class="modal_header-icon">&times;</span>
          </button>
        </div>
      </div>
      <section class="modal-body">
        <div class="row">
          <div class="modal__body--association mt-3">
            <div class="modal__body--association-title">
              <h3>{{ 'servicessuper.services.association' | translate }}</h3>
            </div>
            <div class="clickeables__filters--by-unit">
              <ng-select
                class="actived"
                [ngClass]="{ disabled: !form.get('enable').value }"
                placeholder="{{ 'modal.select' | translate }}"
                formControlName="enable"
                [clearable]="false"
                [searchable]="false"
                (change)="changeAssociation()"
              >
                <ng-option *ngFor="let item of serviceStatus" [value]="item.enabled">
                  {{ item.name | translate }}
                </ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <hr />
        <section class="row" *ngIf="form.get('enable').value">
          <div class="modal__body--zonecoverage mt-4">
            <div class="modal__body--zonecoverage-title">
              <h3>{{ 'zone.coverage' | translate }}</h3>
            </div>
            <div class="clickeables__filters--by-unit">
              <ng-select
                class="custom-category"
                placeholder="{{ 'modal.select' | translate }}"
                typeToSearchText="{{ 'ngSelect.search' | translate }}"
                notFoundText="{{ 'ngSelect.notFound' | translate }}"
                loadingText="{{ 'ngSelect.loading' | translate }}"
                formControlName="name"
                [items]="listCoverage"
                bindLabel="name"
                bindValue="name"
                [typeahead]="coverageAreaInput$"
                [closeOnSelect]="true"
                [clearable]="false"
                (change)="changeModalCoverageArea($event.id)"
              >
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  {{ item.name }}
                </ng-template>
              </ng-select>
            </div>
          </div>
        </section>
        <section class="row modal__body--providers mt-4" *ngIf="form.get('enable').value">
          <div class="modal__body--providers-title">
            <h4>{{ 'zone.providers' | translate }}</h4>
          </div>
          <div class="modal__container--body">
            <div *ngFor="let service of form.get('services').value; let index = index">
              <mat-slide-toggle checked="{{ service.enabled }}" class="mat-slide" (change)="selectService(service, $event.checked, index)">
                {{ service.service.name }}
              </mat-slide-toggle>
            </div>
          </div>
        </section>
        <section class="row">
          <div class="col-md-12 d-flex justify-content-center pt-3 w-100">
            <div class="row btn-succes clickeables-general">
              <button class="btn text-black w-100" type="button" (click)="closeModal()">
                {{ 'servicessuper.services.cancel' | translate }}
              </button>
            </div>
            <div class="row btn-rejection clickeables-general">
              <button class="btn text-white w-100" type="submit">
                {{ 'servicessuper.services.save' | translate }}
              </button>
            </div>
          </div>
        </section>
      </section>
    </section>
  </form>
</ng-template>

<ng-template #modalSuperAdmins let-c="close" let-d="dismiss">
  <app-superadmins-modal [index]="indexRefModal" (onConfirm)="updateBuildingServices($event)"> </app-superadmins-modal>
</ng-template>
