import { MapsAPILoader } from '@agm/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { } from 'googlemaps';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

declare var google: any;

@Component({
  selector: 'app-modal-map-buildings',
  templateUrl: './modal-map-buildings.component.html',
  styleUrls: ['./modal-map-buildings.component.css']
})
export class ModalMapBuildingsComponent implements OnInit {
  @Output()
  sendNewAddress = new EventEmitter<any>();

  isoCode;
  globalLatitud;
  globalLongitud;
  marker: boolean = false;
  jsonSearchAddress: any;
  buildingId: any;
  latitud;
  longitud;
  zoom: number;
  address: string;
  statusButtonSave: boolean = false;
  private geoCoder;
  
  constructor(
    private modalService: NgbModal, 
    private mapsAPILoader: MapsAPILoader, 
    private toastr: ToastrService, 
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.statusButtonSave = true;
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });
  }

  setCountry(latitud, longitud, isoCode, id?) {
    this.statusButtonSave = true;
    this.buildingId = id;
    this.isoCode = isoCode;
    if (this.buildingId === null) {
      this.marker = false;
    }
    if (this.buildingId) {
      this.marker = true;
      this.latitud = parseFloat(latitud);
      this.longitud = parseFloat(longitud);
      this.globalLatitud = this.latitud;
      this.globalLongitud = this.longitud;
      this.zoom = 18;
      this.getAddress(this.latitud, this.longitud);
    } else {
      this.latitud = parseFloat(latitud);
      this.longitud = parseFloat(longitud);
      this.zoom = 8;
      this.globalLatitud = this.latitud;
      this.globalLongitud = this.longitud;
    }
  }

  autocompleteAddress(search?) {
    let isoCode = this.isoCode;
    var requestOptions = {
      method: 'GET',
    };
    fetch(`${environment.geoapifyUrl}=${search}&country=${isoCode}&apiKey=${environment.geoapifyApiKey}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.jsonSearchAddress = result;
        this.zoom = 15;
        this.marker = false;
        this.latitud = this.jsonSearchAddress.features[0].properties.lat;
        this.longitud = this.jsonSearchAddress.features[0].properties.lon;
      })
      .catch((error) => {
        this.toastr.error(this.translate.instant('toastrMessage.error.no_result')+ error);
      });
  }
  
  markerDragEnd($event: any) {
    this.marker = true;
    this.zoom = 18;
    this.latitud = $event.coords.lat;
    this.longitud = $event.coords.lng;
    this.getAddress(this.latitud, this.longitud);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 18;
          this.address = results[0].formatted_address;
          this.statusButtonSave = false;
        } else {
          this.toastr.error(this.translate.instant('toastrMessage.error.no_result'));
        }
      } else {
        this.toastr.error(this.translate.instant('toastrMessage.error.geocoder_error') + status);
      }
    });
  }

  saveNewAddress() {
    this.globalLatitud = this.latitud;
    this.globalLongitud = this.longitud;
    const newAddressBuilding = {
      modalAddress: {
        address: this.address,
        latitude: this.latitud,
        longitude: this.longitud,
      },
    };
    this.sendNewAddress.emit(newAddressBuilding);
    this.closeModal();
  }

  initLatLng() {
    this.marker = true;
    this.statusButtonSave = true;
    this.latitud = parseFloat(this.globalLatitud);
    this.longitud = parseFloat(this.globalLongitud);
    this.getAddress(this.latitud, this.longitud);
    this.zoom = 12;
  }

  openModal(content, windowClass: any) {
    if (this.latitud === '' && this.longitud === '') {
      this.initLatLng();
    }
    this.modalService.open(content, { windowClass: windowClass, backdrop: 'static', keyboard: false, size: 'xl' }).result.then(
      (result) => {
        this.closeModal();
      },
      (reason) => {},
    );
    this.styleModal(`.${windowClass}`);
    return false;
  }

  styleModal(clase: string) {
    setTimeout(() => {
      let modal = <HTMLElement>document.querySelector(`${clase} .modal-dialog`);
      if (modal) {
        modal.style.height = '-webkit-fill-available';
        modal.style.display = 'flex';
        modal.style.alignItems = 'center';
      }
    }, 1);
  }

  closeModal() {
    if (!this.address) {
      this.sendNewAddress.emit(null);
    }
    this.resetValues();
    this.modalService.dismissAll();
  }

  resetValues() {
    this.address = '';
    this.latitud = '';
    this.longitud = '';
  }
}
