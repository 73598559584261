<ng-template #content let-c="close" let-d="dismiss" class="custom--modal-confirmation">
  <div class="custom--modal-form">
    <div class="modal-header py-2 px-2 text-center">
      <h4 class="modal-title">
        {{ title | translate }}
        <p>{{ subtitle | translate }}</p>
      </h4>
    </div>
    <br />
    <div *ngIf="bodyText" class="modal-body">
      {{ bodyText | translate }}
    </div>
    <div class="modal-footer py-2 px-2">
      <button type="button" [ngClass]="buttonsInverse ? 'btn-primary' : 'btn-light'" class="btn btn-sm w-25 rounded" [disabled]="isLoading" (click)="c('Close click')">{{ rejectText | translate }}</button>
      <button type="button" [ngClass]="buttonsInverse ? 'btn-light' : 'btn-primary'" class="btn btn-sm w-25 rounded" [disabled]="isLoading" (click)="doConfirm()">{{ confirmText | translate }}</button>
    </div>
  </div>
</ng-template>
