<div class="">
  <div class="">
    <button type="button" class="modal-buttons-edit btn font-weight-bold"
      (click)="openModal(addressBuilding, 'address-building-modal')">
      {{'modal.edit' | translate}}</button>
  </div>
</div>
<ng-template #addressBuilding let-d="dismiss">
  <div class="modal-body bg-white border">
    <div class="first-section">
      <div class="d-flex align-items-center">
        <img class="times ml-auto float-right" height="15px" width="15px" src="https://munily-public-cdn.s3.amazonaws.com/general/icons/times.png"
          (click)="closeModal()">
      </div>
      <div class="row form-group">
        <button class="btn btn-primary col-md-2 offset-md-1"
          (click)="autocompleteAddress(searchInput.value)">{{'modal.search' | translate}}</button>
        <input type="text" class="form-control col-md-6 offset-md-1" id="search-input"
          (keyup.enter)="autocompleteAddress(searchInput.value)"
          placeholder="{{'modal.placeholder.address' | translate}}" autocorrect="off" autocapitalize="off"
          spellcheck="off" #searchInput>
        <p class="textSearch">
          {{'modal.messageSearch' | translate}}</p>
      </div>
    </div>
    <div class="second-section">
      <div class="modal-map col-12 col-md-12">
        <agm-map style="height: 300px;" [latitude]="latitud" [longitude]="longitud" [zoom]="zoom"
          (mapClick)="markerDragEnd($event)">
          <agm-marker *ngIf="marker" style="height: 300px" [latitude]="latitud" [longitude]="longitud"
            [markerDraggable]="true" (dragEnd)="markerDragEnd($event)">
          </agm-marker>
        </agm-map>
      </div>
    </div>
    <label *ngIf="address" class="font-weight-bold">{{ 'modal.address' | translate }}: {{address}}</label>
    <div Class="buttons">
      <button class="btn btn-primary" (click)="saveNewAddress()" [disabled]="statusButtonSave">{{'modal.save' |
        translate}}</button>
    </div>
  </div>
</ng-template>
