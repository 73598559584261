import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

interface information {
  title: string;
  success: string;
  cancel: string;
  newCommunicate?: boolean;
}

@Component({
  selector: 'super-communicate-modal',
  templateUrl: './communicate-modal.component.html',
  styleUrls: ['./communicate-modal.component.scss']
})
export class CommunicateModalComponent implements OnInit {
  @Input() index: number;
  @Input() count: number;

  @Output() onConfirm = new EventEmitter<Boolean>();
  @Output() onNewCommunicate = new EventEmitter<Boolean>();

  infoToShowHtml: information;

  constructor(private translate: TranslateService) { }


  informationToShow: information[] = [
    {
      title: 'servicessuper.noticemunily.editcommunicate',
      success: 'servicessuper.services.toaccept',
      cancel: 'servicessuper.services.cancel',
      newCommunicate: true,
    },
    {
      title: 'servicessuper.noticemunily.removecommunicate',
      success: 'servicessuper.services.toaccept',
      cancel: 'servicessuper.services.cancel',
      newCommunicate: false,
    },
    {
      title: 'servicessuper.noticemunily.discardchanges',
      success: 'servicessuper.services.toaccept',
      cancel: 'servicessuper.services.cancel',
      newCommunicate: false,
    },
    {
      title: 'servicessuper.noticemunily.deletesenders',
      success: 'servicessuper.services.toaccept',
      cancel: 'servicessuper.services.cancel',
      newCommunicate: false,
    },
    {
      title: '',
      success: 'servicessuper.services.toaccept',
      cancel: 'servicessuper.services.cancel',
      newCommunicate: false,
    },
  ];

  ngOnInit(): void {
    this.infoToShowHtml = this.informationToShow[this.index];

    if (this.index == 4) this.informationToShow[this.index].title = this.translate.instant('servicessuper.noticemunily.noticemunilygenerate', { count: this.count })
  }

  confirmModal(event: boolean) {
    this.onConfirm.emit(event);
  }

  newCommunicate(event: Boolean) {
    this.onNewCommunicate.emit(event);
  }
}
