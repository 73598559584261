import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAccountingUnits',
})
export class FilterAccountingUnitsPipe implements PipeTransform {
  transform(value: any, arg: any, associated:boolean, reload:number): any {
    let array = [];
    if (arg === '' || arg === null) return value;
    for (const post of value) {
      if(associated){
        if (post.accountingName.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
          array.push(post);
        }
      }else{
        if (post.value.accountingName.indexOf(arg.toLowerCase()) > -1) {
          array.push(post);
        }
      }
      
    }
    return array;
  }
}
