import { Pipe, PipeTransform } from "@angular/core";
import { ServicesService } from "src/app/services/services.service";
import { CoverageAreaService } from "src/app/services/coverage-area.service";

@Pipe({
    name: 'searchNameService'
})


export class SearchNameService implements PipeTransform {
    constructor(
        private servicesServices: ServicesService,
    ){}
    transform(value: string, ...args: any[]) {
        if (value){
            this.servicesServices.getServicesId(value).subscribe(Response => {
                let data = Response;
                if (!data) return;
            })
        }
    }
}