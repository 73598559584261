import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//SERVICES
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-area-configuration-modal',
  templateUrl: './area-configuration-modal.component.html',
  styleUrls: ['./area-configuration-modal.component.scss']
})
export class AreaConfigurationModalComponent implements OnInit {
  @Output() confirmUpdate = new EventEmitter();
  @ViewChild('areaAdviceModal', { static: true }) areaAdviceModal: ElementRef;
  modalRef: any;

  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  openModal():void {
    this.modalRef = this.modalService.open(this.areaAdviceModal, { backdrop: 'static', keyboard: false, centered: true, size: 'md' });
  }

  closeModal():void {
    this.modalRef.close();
  }

  doConfirm():void {
    this.confirmUpdate.emit(true);
    this.closeModal();
  }
}
