<div class="custom--modal-form">
  <div class="modal_header d-flex align-items-center pb-2">
    <button type="button" class="modal_header-close px-0" aria-label="Close" (click)="closeModal(false)">
      <span aria-hidden="true" class="modal_header-icon">&times;</span>
    </button>
  </div>

  <div class="row">
      <div class="col-md-12">
          <div class="image-filter mt-3">
              <img src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/filter.png" alt="Filter">
          </div>
      </div>
      <div class="col-md-12">
          <div class="title mt-3">
              <p>{{ 'servicessuper.services.searchfilter' |
                  translate}}</p>
          </div>
      </div>
      <div class="col-md-12 d-flex flex-column align-items-center w-100">
          <div class="row  btn-rejection clickeables-general">
              <button class="btn text-white w-100" type="button" (click)="confirmModal(true)">
                  {{ 'servicessuper.services.toaccept' |
                  translate}}
              </button>
          </div>
          <div class="row  btn-succes  clickeables-general">
              <button class="btn text-black w-100" type="button" (click)="closeModal(false)">
                  {{ 'servicessuper.services.cancel' |
                  translate}}
              </button>
          </div>
      </div>
  </div>
</div>
