import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtercategory'
})

export class FilterCategory implements PipeTransform {
  transform(value: string, pos: number, index: number): any {
    pos += 1;
    if (pos != index) {
      return value + ","
    } else {
      return value
    }
  }
}