import { User } from "firebase";
import { Config } from "protractor";
import { Unit } from "./administrative";
import { Building } from "./buildings.interface";

export enum AccessCardStatus {
  ACTIVE = 'active',
  DELETED = 'deleted',
  EXPIRED = 'expired',
}

export enum AccessCardType {
  VISIT = 'visit', //Visitante
  PATIENT = 'patient', // Paciente
  COLLABORATOR = 'collaborator' // Colaborador
}

export enum AccessType {
  tenant = 'tenant',
  unique = 'unique',
  frequent = 'frequent'
}

export enum typeVehicle {
  car = "carro",
  motorcycle = "moto",
  other = "otro"
}

export class FrequentVehicle {
  plate?: string;
  color?: string;
  model?: string;
  brand?: string;
  typeVehicle?: string;
}

export class SecurityStateDTO {
  frequentAccessId: string;
  personInvitationType: PersonInvitationType;
  unit: {
    id: string;
    number: string;
  }
  date: Date | String;
  guest: {
    name: string;
    dni: string;
    phone: string;
    photo: string;
    email: string;
  }
  status: string;
  accessEntryStatus: AccessEntryStatus;
  invitationId: string;
  accessEntry: {
    doorName: string,
    doorId: string,
    date: Date,
    status: AccessEntryStatus;
    checked: boolean
  }[];
  vehicle?: FrequentVehicle;
}

export enum AccessEntryStatus {
  active = 'active',
  displacement='displacement',
  timeExceeded= 'time-exceeded',
  completed= 'completed'
}

export class InvitationsHistoryDTO {
  invitationId: string;
  date: Date;
  status: string;
  accessEntryStatus?: AccessEntryStatus;
  accessEntry?: {
    doorName?: string;
    doorId?: string;
    date?: Date;
    status?: AccessEntryStatus;
    checked?: boolean;
  }[]
}

export interface reasonExtendTime {
  type: string,
  value: string
}

export class InvitationExtendReq {
  frequentAccessId: string;
  payload: InvitationExtendTimeDTO;
}

export class InvitationExtendTimeDTO {
  invitationId: string;
  hours: number;
  min: number;
  reason: string;
}

export interface extendDetail {
  reason: string;
  userExtended: string;
  hourExtend: Date[];
}


export enum PersonInvitationType {
  FAMILY = 'family',
  TRAINER = 'trainer',
  EMPLOYEE = 'employee',
  PROVIDER = 'provider',
  GUEST = 'guest',
  VISIT = 'visit',    // NUEVO
  PATIENT = 'patient', // NUEVO
  COLLABORATOR = 'collaborator'  // NUEVO
}

export class WeekDay {
  entryTime: string;
  departureTime: string;
}

export class ScheduleAccess {
  '0': WeekDay[];
  '1': WeekDay[];
  '2': WeekDay[];
  '3': WeekDay[];
  '4': WeekDay[];
  '5': WeekDay[];
  '6': WeekDay[];
}

export class ScheduleConfig {
  allDaysAccess: boolean;
  sameSchedule: boolean;
}

export class UpdateFrequentAccessControlDTO {
  schedule: ScheduleAccess;
  personInvitationType: PersonInvitationType;
  scheduleConfig: ScheduleConfig;
  dueDate?: Date;
  comment?: string;
}

export class Vehicle {
  plate?:string;
  color?:string;
  model?: string;
  brand?: string;
  typeVehicle: string;
}

export class CreateFrequentAccessControlDTO {
  schedule: ScheduleAccess;
  accessType: AccessType;
  unitId: string;
  personInvitationType: PersonInvitationType;
  guestsInput: {
    name: string;
    dni: string;
    phone: {
      phone: string;
      phoneDetail?: {
        countryCode: string;
        dialCode: string;
        e164Number: string;
        nationalNumber: string;
      }
    }
    base64?: string;
    imageFormat?: string;
    email?: string;
  };
  scheduleConfig: ScheduleConfig;
  qr?:string;
  dueDate?: Date;
  comment?: string;
  vehicle?: Vehicle;
}

export interface ResponsePaginatedFrenquentAccess{
  data: Paginated
}

interface Paginated {
  totalDocs: number,
  limit: number,
  page: number,
  totalPages: number,
  nextPage: number,
  prevPage: number,
  pagingCounter: number,
  hasPrevPage: boolean,
  hasNextPage: boolean,
  docs: FrequentAccess[]
}

export class FrequentAccessControlDTO{
  id: string;
  guest: {
    id: string;
    name: string;
    dni: string;
    phone: string; //PENDIENTE EL CAMBIO
    photo?: string;
    email?: string;
  };
  unit:{
    number: string;
    id: string;
  }
  editable: boolean; // SI el acceso fue creado por un residente no puede ser editado ni eliminado
  accessType: AccessType;
  personInvitationType: PersonInvitationType;
  status: AccessCardStatus;
  schedule: ScheduleAccess;
  scheduleConfig?: {   // Solo aplica para los accesos creados desde apertura de puertas
    allDaysAccess: boolean;
    sameSchedule: boolean;
  }
  dueDate?: Date | string;
  qr?:string;
  createdAt?: Date;
  comment?: string;
  lastAccess?: Date; // Para los casos que aplique (Fecha de ultimo acceso)
  vehicle?: Vehicle;
}

export class FrequentAccess{
  user: User | string;
  guest: any;
  unit: Unit | string;
  building: Building | string;
  status: AccessCardStatus;
  config: Config;
  isValidTime?: boolean;
  accessType: AccessType; ////// NUEVO
  frequentAccessToken: string;
  qr: string;
  accessConfig:{  ////// NUEVO
    dinamicLink: string;
  }
  search: String[];
  dueDate: Date | null;
  comment?: string;
  schedule: ScheduleAccess;
  scheduleConfig: ScheduleConfig;
  updatedAt?: Date;
  createdAt?: Date;
}
