<section class="header p-4 w-100">
	<div class="header--container w-100 d-flex justify-content-between align-items-center">
		<div class="header--title">
			<h1>{{'superadministration.title' | translate}} <img [src]="icons.infoBlue" alt=""> </h1>
		</div>
		<div class="row">
			<div class="header--buttons pr-2">
				<div class="dropdown">
					<button class="btn btn-outline-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
						{{ 'onboarding.transactions.moreOptions' | translate }}
					</button>
					<div class="dropdown-menu shadow-style-options">
						<a class="dropdown-item" (click)="openModalUnitUsers()">{{ 'table.importDB' | translate }}</a>
						<div class="dropdown-divider mt-0 mb-0"></div>
						<a class="dropdown-item" (click)="sendDbToExcel()">{{ 'table.downloadDB' | translate }}</a>
					</div>
				</div>
			</div>
			<div class="header--buttons">
				<button (click)="openModalUser()" class="btn btn-outline-primary">+ {{'superadministration.addUser' | translate}}</button>
			</div>
		</div>
	</div>

	<hr>
</section>

<section class="filters px-3 w-100">
	<div class="filter--container">
		<app-common-filters (queryOutput)="getQueryFilter($event)" [buildingId]="buildingId" [showFilters]="showFilters"></app-common-filters>
	</div>
</section>

<section>
	<app-dynamic-table
		[headers]="headers"
		[data]="data"
		[select]="select"
		[haveActions]="haveActions"
		[paginated]="paginated"
		(getPageOutput)="getUsersByPage($event)"
		(disable)="disableConfirm($event)"
		(remove)="removeConfirm($event)"
		(enable)="enableConfirm($event)"
		(edit)="edit($event)"
		(sort)="sort($event)"
	></app-dynamic-table>
</section>

<section class="">
	<app-modal
		[show]="displayModal"
		[type]="activationUser"
		[title]="title"
		[subtitle]="subtitle"
		[note]="note"
		(success)="chooseActions()"
		(denied)="denied()"
	></app-modal>
</section>
<app-success-process-modal #modalSuccessProcess></app-success-process-modal>
<app-unity-modal-users-tab #modalUnitUsers (onComplete)="onComplete()"></app-unity-modal-users-tab>
