import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class ServicesService extends DataService {
  headers: HttpHeaders;
  sharedData: any;
  categoriesId: string[] = [];
  numberSelected: number = 1;
  constructor(private http: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);
    this.headers = new HttpHeaders({
      authorization: JSON.parse(localStorage.getItem('currentUser')).token,
      'fb-token': JSON.parse(localStorage.getItem('currentUser')).fbToken,
    });
  }

  getAllServices(page, limit, status, search, find): Observable<any> {
    const params = search ? `&${search}` : '';
    find = find ? `&search=${find}` : '';

    return this.http
      .get(`${environment.apiUrl}/super/v1/services?sortField=name&sortOrder=1&page=${page}&size=${limit}&status=${status}${params}${find}`, {
        headers: this.headers,
      })
      .pipe(map((res: any) => res));
  }

  getServicesId(serviceId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/super/v1/services/${serviceId}`, { headers: this.headers }).pipe(map((response: any) => response.data));
  }

  getAllCategories(page, limit, status, search, find): Observable<any> {
    const params = search ? `&${search}` : '';
    find = find ? `&search=${find}` : '';

    return this.http
      .get(`${environment.apiUrl}/super/v1/categories?page=${page}&size=${limit}&status=${status}${params}${find}`, { headers: this.headers })
      .pipe(map((res: any) => res));
  }

  newStoreService(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/super/v1/services`, data, { headers: this.headers }).pipe(map((res: any) => res));
  }

  newUpdateService(id: string, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/super/v1/services/${id}`, data, { headers: this.headers }).pipe(map((res: any) => res));
  }

  deleteService(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/super/v1/services/${id}`, { headers: this.headers }).pipe(map((res: any) => res));
  }
}
