import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { create } from 'lodash';
import moment from 'moment';
import { isIterable } from 'rxjs/internal-compatibility';
import { IconsGlobal } from 'src/app/pages/tenants/users-tab/users-tab.component';
import { DynamicTableService } from 'src/app/services/dynamic-table.service';
import WaveSurfer from 'wavesurfer.js'

export enum TypeDate {
  range = 'range',
  date = 'date',
}

export enum IconsTable {
  switchTable = 'https://munily-public-cdn.s3.amazonaws.com/general/SwitchOrder.png',
  grayLeftArrow = 'https://munily-public-cdn.s3.amazonaws.com/general/grayLeftArrow.png',
  blueRightArrow = 'https://munily-public-cdn.s3.amazonaws.com/general/blueRightArrow.png',
  disableGray = 'https://munily-public-cdn.s3.amazonaws.com/common-components/dynamic-table/dashboard/disableGrey.png',
  disableHover = 'https://munily-public-cdn.s3.amazonaws.com/common-components/dynamic-table/dashboard/disableRed.png',
  enableGray = 'https://munily-public-cdn.s3.amazonaws.com/common-components/dynamic-table/dashboard/enableGray.png',
  enableHover = 'https://munily-public-cdn.s3.amazonaws.com/common-components/dynamic-table/dashboard/enableGreenHover.png',
  removeGray = 'https://munily-public-cdn.s3.amazonaws.com/common-components/dynamic-table/dashboard/removeGray.png',
  removeHover = 'https://munily-public-cdn.s3.amazonaws.com/common-components/dynamic-table/dashboard/removeHover.png',
  isCheck = 'https://munily-public-cdn.s3.amazonaws.com/general/icons/check-yellow.png',
  isNotCheck = 'https://munily-public-cdn.s3.amazonaws.com/general/icons/check-gray.png',
}

export interface CollapseTableData {
  headers?: string[];
  select?: string[];
}

export interface Paginated {
  page: number;
  totalPages: number;
}

export const Status = {
  active: true,
  inactive: false,
  true: 'yes',
  false: 'no',
};

export enum StatusV2 {
  active = 'disable',
  inactive = 'enable',
  disable = 'disable',
  enable = 'enable',
  remove = 'remove',
  update = 'update',
}

export enum Actions {
  edit = 'edit',
  disable = 'disable',
  active = 'disable',
  inactive = 'enable',
  enable = 'enable',
  view = 'view',
  remove = 'remove',
  download = 'download'
}

//version #2, Region of check
///parameters received by the component, it is allowed to add more parameters to process the data.
export interface ReceivingObjects {
  id?: string;
  name?: string;
  isEdit: boolean;
}

///parameters sent by the component, it is allowed to add more parameters to send to another component.
export interface SendingObjects {
  id?: string;
  name?: string;
  selectedRows?: string[];
}

//three states of verification
export enum TypeEventLoadTable {
  controlsDisabled = 'controlsDisabled', ///Deactivate table checks allows you to deactivate the table checks
  activatedControls = 'activatedControls', ///Active table checks, allows you to activate the table checks
  loadedData = 'loadedData', ///sending data to another component, data sending and loading...
  saveData = 'saveData', ///save data
  deleteData = 'deleteData'
}

export interface IChecks {
  TypeEventAtTable: TypeEventLoadTable;
  DataReception: ReceivingObjects;
}

export enum ViewComponents {
  pqrs = 'pqrs',
  calls = 'calls'
}

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
})
export class DynamicTableComponent implements OnInit, OnChanges, AfterViewInit {
  actions = Actions;
  Status = Status;
  @Input() headers: string[] = [];
  @Input() data: any[];
  @Input() select: string[] = [];
  @Input() haveActions: Actions[] = [];
  @Input() paginated: Paginated;
  @Input() collapse: CollapseTableData;
  @Input() haveChecks: IChecks = { TypeEventAtTable: TypeEventLoadTable.controlsDisabled, DataReception: { isEdit: false } };
  @Input() viewComponent: ViewComponents;


  filtersCheck: boolean;

  @Output() getPageOutput = new EventEmitter<string>();
  @Output() edit = new EventEmitter();
  @Output() disable = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() enable = new EventEmitter();
  @Output() sort = new EventEmitter();
  @Output() download = new EventEmitter();
  // @Output() seeDetails = new EventEmitter();
  @Output() view = new EventEmitter();
  @Output() onHaveChecks = new EventEmitter<{ isCreateModal: TypeEventLoadTable; data: SendingObjects }>();

  @ViewChildren('waveform') waveformElements: QueryList<ElementRef<any>>;
  private waveforms: WaveSurfer[] = [];
  isPlaying: {playerRow:number, playing:boolean} = { playerRow : 0, playing: false };

  isCheckActive: boolean = false;
  selectedRows: string[] = [];
  // selectedRows
  icons = IconsTable;
  iconsGlobal = IconsGlobal;
  next: boolean[] = [false, false, false];
  next1: number;
  next2: number;
  next3: number;
  countSelectedRows: number = 0;
  sorted: { field: string; selected: boolean };

  collapsedRow = [];

  eventsTable = TypeEventLoadTable;
  constructor(private translateService: TranslateService,
              private dynamicTableService : DynamicTableService
    ) { }

  ngOnInit(): void {
    const haveActions = !this.select.includes('Acciones') && !this.headers.includes('Acciones')
    if (this.haveActions && haveActions) {
      this.headers.push('action');
      this.select.push('Acciones');
    }
    this.hasNext();
  }

  ngOnChanges(): void {
    this.countSelectedRows = 0;
    if (!this.data || !this.onTypeEventTable(this.haveChecks.TypeEventAtTable)) return;
    for (let index = 0; index < this.data.length; index++) {
      const isExistAtSelectedRows = this.selectedRows.findIndex((rows) => rows === this.data[index].id);

      if (this.data[index].iconCheck && isExistAtSelectedRows === -1) {
        this.selectedRows.push(this.data[index].id);
      }
      if (this.data[index].iconCheck) this.countSelectedRows++;
    }
    if (this.countSelectedRows === this.data.length) this.isCheckActive = true;
    if (this.countSelectedRows !== this.data.length) this.isCheckActive = false;
  }

  ngAfterViewInit(): void {
    //We obtain the references of the available players in the table, and assign the URL of the audio corresponding to the row
    this.waveformElements.forEach((element, index)=>{
      let rowIndex = element.nativeElement.id;
      this.waveforms.push(
        WaveSurfer.create({
          container: element.nativeElement,
          waveColor: '#FFFFFF',
          progressColor: '#383351',
          cursorColor: '#ddd5e9',
          url: this.data[rowIndex].recording.urlRecord,
          height:25,
          width:70,
          barRadius: 5,
          barWidth: 3,
          barHeight: 0.7, 
          barGap: 3,
          dragToSeek: true
        })
      );
      this.playerEvents(this.waveforms[this.waveforms.length - 1]);
    });
  }

  showAction(action: Actions) {
    return this.haveActions.includes(action);
  }

  isIterable(item: any) {
    this.hasNext();
    if (!item) return false;
    const is = item.split(' ').length > 1;
    if (is) return true;
    return false;
  }

  getPropertyInsideIterable(prop1: any, prop2: string) {
    let conditional = null;
    if (prop2.includes('?')) {
      const propsAndConditional = prop2.split('?');
      prop2 = propsAndConditional[0];
      conditional = propsAndConditional[1].split('=');
    }
    if (prop1 && prop1[0] && typeof prop1 == 'object') {
      let output = '';
      prop1.forEach((e) => {
        if (e && e[prop2] && (conditional ? e[conditional[0]].toString() == conditional[1].toString() : true)) {
          output = `${output} ${e[prop2]}`;
        }
      });
      return output;
    }
    return ' - ';
  }

  handleDate(obj: any, items: any) {
    let typeDate: TypeDate = items.pop();
    const dateMain = obj[items[0]];
    if (typeDate == TypeDate.date) {
      return moment(dateMain).format('DD/MM/YYYY');
    }
    if (typeDate == TypeDate.range) {
      const firtsDate = obj[items[0]];
      const secondDate = obj[items[1]];
      const dateStart = moment(firtsDate).format('H:mm a');
      const dateEnd = moment(secondDate).format('H:mm a');
      return `${dateStart} - ${dateEnd}`;
    }
    return '-';
  }

  //We get the specified property of the object
  getPropertyInside(obj: any, properties: string) {
    const items = properties.split('.');
    const itemsDate = properties.split('/').map((e) => e.trim());
    if (itemsDate.length > 1) {
      return this.handleDate(obj, itemsDate);
    }
    const props = properties.split(' ');
    const propObject = obj[props[0]];
    if (!propObject) {
      return ' - ';
    }
    const display = propObject[props[1]];
    if (items.length > 2) {
      return this.getTranslation(display, items[items.length - 1]);
    }
    if (items.length == 2) {
      const i18nKey = props[1].split('.')[1];
      if (i18nKey == 'availableDays') {
        const outputAvailableDays = this.getTranslation(obj[props[0]], i18nKey);
        const output = outputAvailableDays.map((element) => element.substr(0, 3));
        return output;
      }
      return this.getTranslation(obj[props[0]], i18nKey);
    }
    if (!display) {
      return this.getPropertyInsideIterable(propObject, props[1]);
    }
    return display;
  }

  finalVerification(output?: any) {
    if (output) return output;
    else return ' - ';
  }

  getPlainProperty(display?: string) {
    if (Status[display?.toString()]) {
      const adverb = Status[display.toString()];
      return this.getTranslation(adverb, 'adverb');
    }
    return this.finalVerification(display);
  }

  getTranslation(display: string, i18nKey: string) {
    this.hasNext();
    if (isIterable(display) && display.length > 0 && typeof display != 'string') {
      display = display as any;
      let output = '';
      if (display.length > 1) {
        for (let i = 0; i < display.length; i++) {
          if (output !== '') output = output + ', ';
          const element = display[i];
          const translated = this.translateService.instant(`${i18nKey}.${element}`);
          output = output + translated;
        }
      } else {
        output = this.translateService.instant(`${i18nKey}.${display[0]}`);
      }
      return output;
    }
    if (typeof display == 'object') {
      return this.handleExceptionIsObject(i18nKey, display);
    }
    const output = this.translateService.instant(`${i18nKey}.${display}`);
    return this.finalVerification(output);
  }

  handleExceptionIsObject(i18nKey: string, display: any) {
    const array = Object.keys(display).map((key) => [display[key]]);
    let output: any = [];
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (element[0] && element[0].length > 0) {
        const item = this.translateService.instant(`${i18nKey}.${i.toString()}`);
        output.push(item);
      }
    }
    return this.finalVerification(output);
  }

  hasNext() {
    if (!this.paginated) return;
    this.setNextPages();
    let page = this.paginated.page;
    let totalPages = this.paginated.totalPages;
    let count = 0;
    while (count < this.next.length) {
      const hasNext = page + 1 + count;
      if (hasNext < totalPages) {
        this.next[count] = true;
      } else {
        this.next[count] = false;
      }
      count = count + 1;
    }
  }

  setNextPages() {
    this.next1 = this.paginated.page + 1;
    this.next2 = this.paginated.page + 2;
    this.next3 = this.paginated.page + 3;
  }

  changePage(pageNumber: number) {
    if (pageNumber < 1) {
      pageNumber = 1;
    }

    if (pageNumber > this.paginated.totalPages) {
      pageNumber = this.paginated.totalPages;
    }
    this.getPageOutput.emit(pageNumber.toString());
  }

  editAction(data) {
    this.edit.emit(data);
  }

  chooseAction(status, data) {
    if (status) this.disableAction(data);
    else this.enableAction(data);
  }

  disableAction(data) {
    this.disable.emit(data);
  }

  removeAction(data) {
    this.remove.emit(data);
  }

  enableAction(data) {
    this.enable.emit(data);
  }

  viewAction(data) {
    this.view.emit(data);
  }

  sortByName(index: number) {
    this.sorted = { field: this.select[index], selected: this.sorted?.selected ? false : true };
    const order = this.sorted.selected ? '-1' : '1';
    this.sort.emit({ field: this.sorted.field, order });
  }

  collapseTable(pos: string) {
    if (this.collapsedRow.includes(pos)) this.collapsedRow = this.collapsedRow.filter((e) => e != pos);
    else this.collapsedRow.push(pos);

    const divs = document.getElementsByClassName(pos.toString()) as any;
    for (let i = 0; i < divs.length; i++) {
      const div = divs[i];
      if (!div.className.includes('show')) this.collapseOn(div, pos);
      else this.collapseOff(div, pos);
    }
  }

  collapseOn(div: HTMLDivElement, pos: string) {
    div.className = `${pos} collapsing`;
    setTimeout(() => {
      div.className = `${pos} collapse show showOn`;
    }, 300);
  }

  collapseOff(div: HTMLDivElement, pos: string) {
    div.className = `${pos} collapsing`;
    setTimeout(() => {
      div.className = `${pos} collapse`;
    }, 300);
  }

  selectedAllUsers(isCheckActive: boolean) {
    this.data.forEach((d) => {
      if ((!d.iconCheck && !isCheckActive) || (d.iconCheck && isCheckActive)) {
        this.usersSelection(d);
      }
    });
    if(this.isCheckActive) this.dynamicTableService.getAllUsersIdFromQuery();
    else if(!this.isCheckActive) this.dynamicTableService.removeAllUsers();
    this.isCheckActive = !isCheckActive;
  }

  onEventTable(i: string, prop?: any) {
    if (this.collapse) this.collapseTable(i);
    if (this.onTypeEventTable(this.haveChecks.TypeEventAtTable)) this.usersSelection(prop);
  }

  usersSelection(prop?: any) {
    const index = this.selectedRows.findIndex((user) => user === prop.id);

    if (index != -1) {
      this.dynamicTableService.removeUserFromList(this.selectedRows[index]);
      this.selectedRows.splice(index, 1);
      prop.iconCheck = !prop.iconCheck;
      this.countSelectedRows--;
      if (this.countSelectedRows !== this.data.length) {
        this.isCheckActive = false;
      }

      return;
    }

    if (index === -1) {
      this.dynamicTableService.addUserTotheList(prop.id);
      this.selectedRows.push(prop.id);
      prop.iconCheck = !prop.iconCheck;
      this.countSelectedRows++;
      if (this.countSelectedRows === this.data.length) {
        this.isCheckActive = true;
      }

      return;
    }
  }

  onEventEmitFooter(event: boolean) {
    if (!event) this.onResetTable();
    const dataEmit = {
      isCreateModal: event ? TypeEventLoadTable.saveData : TypeEventLoadTable.controlsDisabled,
      data: event ? { ...this.haveChecks.DataReception, selectedRows: this.selectedRows } : {},
    };
    this.onHaveChecks.emit(dataEmit);
  }

  enableCheckTable(create:boolean) {
    const { id } = this.haveChecks.DataReception;
    if(create){
      this.haveChecks.TypeEventAtTable = TypeEventLoadTable.activatedControls;
      this.haveChecks.DataReception.isEdit = false;
    }
    this.onHaveChecks.emit({ isCreateModal: create ? TypeEventLoadTable.loadedData : TypeEventLoadTable.deleteData, data: { id } });
  }

  onResetTable() {
    this.selectedRows.length = 0;
    this.haveChecks.TypeEventAtTable = TypeEventLoadTable.controlsDisabled;
    this.countSelectedRows = 0;
    this.isCheckActive = false;
  }

  onTypeEventTable(event: TypeEventLoadTable): boolean {
    return this.eventsTable.controlsDisabled !== event;
  }

  onEventDownload(data: any) {
    this.download.emit(data);
  }

  //AudioPlayer Functions
  playerEvents(waveform:WaveSurfer){
    let rowIndex = waveform.options.container['id'];
    waveform.once('interaction', ()=> waveform.play());
    waveform.on('play', ()=> { this.isPlaying = { playerRow: rowIndex, playing:true }});
    waveform.on('pause', ()=> { this.isPlaying = { playerRow: rowIndex, playing:false }});
  }

  playAudio(rowIndex:string):void{
    let waveformToPlay:WaveSurfer[] = this.waveforms.filter((wave)=> wave.options.container['id'] == rowIndex);
    if(this.isPlaying.playing){
      let waveformToStop:WaveSurfer[] = this.waveforms.filter((wave)=> wave.options.container['id'] == this.isPlaying.playerRow);
      waveformToStop[0].pause();
    }
    if(waveformToPlay && waveformToPlay.length > 0) waveformToPlay[0].play();
  }

  stopAudio(rowIndex:string):void{
    let waveform:WaveSurfer[] = this.waveforms.filter((wave)=> wave.options.container['id'] == rowIndex);
    if(waveform && waveform.length > 0) waveform[0].pause();
  }
}
