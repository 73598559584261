import { Directive, EventEmitter, HostBinding, HostListener, Output } from "@angular/core";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

export interface FileHandle {
  file: File,
  url: SafeUrl
}

@Directive({
  selector: '[appDragAndDrop]'
})

export class DragAndDropDirective {

  constructor(private sanitizer: DomSanitizer, private translateService: TranslateService) { }
  @Output() files: EventEmitter<FileHandle[]> = new EventEmitter();
  @HostBinding("style.border") private border = "3px dashed #D7D7D7";

  @HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.border = "4px solid #3092E9";
    let elem:HTMLElement= evt.target as HTMLElement;
    if(elem.className.indexOf('inside-import') != -1){
      let h1 = document.querySelector('.empty-state > h1') as HTMLElement;
      if(h1){
        h1.style.color = "#3092E9";
        h1.innerHTML =`<strong class="inside-import" style="font-family: 'PoppinsBold';">${this.translateService.instant('accounts-status.import-section.title.drop-now')}</strong>`;
      }

      let p = document.querySelector('.empty-state > p') as HTMLElement;
      if(p) p.style.display = "none";

      let img = document.querySelector('.empty-state > img') as HTMLImageElement;
      if(img) img.src = "https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/uploadBlue.png";
    }
  }

  @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.border = "3px dashed #D7D7D7";
    let elem:HTMLElement= evt['fromElement'] as HTMLElement;
    if(elem.className.indexOf('inside-import') == -1){
      this.restoreDefaultSets();
    }
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.restoreDefaultSets();

    let files: FileHandle[] = [];
    for (let i = 0; i < evt.dataTransfer.files.length; i++) {
      const file = evt.dataTransfer.files[i];
      const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
      files.push({ file, url });
    }
    if (files.length > 0) {
      this.files.emit(files);
    }
  }

  restoreDefaultSets(){
    this.border = "3px dashed #D7D7D7";
    let h1 = document.querySelector('.empty-state > h1') as HTMLElement;
    if(h1){
      h1.textContent = this.translateService.instant('accounts-status.import-section.title.drop-now');
      h1.innerHTML = `<strong class="inside-import" style="font-family: 'PoppinsBold';">${this.translateService.instant('accounts-status.import-section.title.h-1')}</strong> <br>${this.translateService.instant('accounts-status.import-section.title.h-2')}`;
      h1.style.color = "#737373";
    }
    let p = document.querySelector('.empty-state > p') as HTMLElement;
    if(p) p.style.display = "block";
    let img = document.querySelector('.empty-state > img') as HTMLImageElement;
    if(img) img.src = "https://munily-public-cdn.s3.amazonaws.com/onboarding/dashboard/icons/upload.png";
  }

}
