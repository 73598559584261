import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class CoverageAreaService extends DataService {
  headers: HttpHeaders;
  sharedData: any;
  constructor(private http: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);
    this.headers = new HttpHeaders({
      authorization: JSON.parse(localStorage.getItem('currentUser')).token,
      'fb-token': JSON.parse(localStorage.getItem('currentUser')).fbToken,
    });
  }

  listCoverageArea(page, limit, search, find, status): Observable<any> {
    const params = search ? `&${search}` : '';
    find = find ? `&search=${find}` : '';

    return this.http.get(
      `${environment.apiUrl}/super/v1/munily-trucks/region?sortField=name&sortOrder=1&page=${page}&size=${limit}&status=${status}${params}${find}`,
    );
  }

  getCoverageAreas(search?, status?, limit?: number): Observable<any> {
    let params = new HttpParams();
    if (search) params = params.set('search', search);
    if (status) params = params.set('status', status);
    if (limit) params = params.set('rowsPerPage', limit.toString());

    return this.http
      .get(`${environment.apiUrl}/super/v1/munily-trucks/region`, { headers: this.headers, params: params })
      .pipe(map((Response: any) => Response.data.docs));
  }

  getCoverageArea(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/super/v1/munily-trucks/region/${id}`, { headers: this.headers }).pipe(map((res: any) => res));
  }

  newCoverageArea(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/super/v1/munily-trucks/region`, data, { headers: this.headers }).pipe(map((res: any) => res));
  }

  editCoverageArea(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/super/v1/munily-trucks/region/${id}`, data, { headers: this.headers }).pipe(map((res: any) => res));
  }

  deleteCoverageArea(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/super/v1/munily-trucks/region/${id}`, { headers: this.headers }).pipe(map((res: any) => res));
  }
}
